<app-data-table
  [columns]="columns"
  [data]="categoriesTable()"
  [totalRecords]="totalRecords()"
  [filters]="filters"
  [loading]="loading()"
  (add)="onAdd()"
  (edit)="onEdit($event)"
  (removeSelected)="onRemoveSelected($event)"
  (pageChange)="onPage($event)"
/>

<app-add-category
  [(visible)]="dialogVisible"
  [title]="dialogTitle"
  [subtitle]="dialogSubtitle"
  (save)="onSave($event)"
>
</app-add-category>
