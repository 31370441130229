import {
  Component,
  inject,
  OnInit,
  signal,
  computed,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ClientService } from '../../services/client.service';
import { Client, Clients } from '../../interfaces/client';

@Component({
  selector: 'app-client-selector',
  standalone: true,
  imports: [DropdownModule, ReactiveFormsModule],
  templateUrl: './client-selector.component.html',
})
export class ClientSelectorComponent implements OnInit {
  @Input() dialogVisible = false;
  @Output() clientSelected = new EventEmitter<Client>();

  private _clientService = inject(ClientService);
  private clientsLoaded = false;
  clients = signal<Clients>({} as Clients);
  clientOptions = computed(() => {
    const data = this.clients().data;
    if (Array.isArray(data)) {
      return data.map((client) => ({
        label: client.attributes.name,
        value: client,
      }));
    } else {
      return [];
    }
  });

  ngOnInit(): void {
    // Escuchar cambios en la visibilidad del diálogo
    if (this.dialogVisible) {
      this.loadClientsIfNeeded();
    }
  }

  ngOnChanges(): void {
    // Cargar clientes cuando el diálogo se hace visible
    if (this.dialogVisible && !this.clientsLoaded) {
      this.loadClientsIfNeeded();
    }
  }

  private loadClientsIfNeeded(): void {
    if (!this.clientsLoaded) {
      this._clientService.getAll().subscribe({
        next: (clients) => {
          this.clients.set(clients);
          this.clientsLoaded = true; // Marcar como cargado
        },
        error: () => {
          console.error('Error al cargar clientes');
        },
      });
    }
  }

  onClientChange(event: any): void {
    this.clientSelected.emit(event.value);
  }
}
