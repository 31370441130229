<div class="flex justify-content-end w-full">
  <p-button
    [icon]="agent.data ? 'pi pi-pencil' : 'pi pi-plus'"
    [label]="agent.data ? 'Editar' : 'Añadir'"
    size="small"
    (onClick)="addAgent()"
    [text]="true"
  >
  </p-button>
</div>

@if (!agent.data) {
<p-messages
  [(value)]="messages"
  escape="false"
  [closable]="false"
  [enableService]="false"
  class="w-10"
/>
} @else {
<div
  class="flex align-items-center justify-content-between hover:surface-hover border-round-md mx-2 p-3"
>
  <div class="flex align-items-center gap-2">
    <p-avatar label="{{ agent.data.attributes.name.substring(0, 1) }}" />
    <div class="flex flex-column justify-content-start gap-1">
      <label class="font-semibold"> {{ agent.data.attributes.name }}</label>
      <label class="text-sm"> {{ agent.data.attributes.email }}</label>
      <label class="text-sm"> {{ agent.data.attributes.phone }}</label>
    </div>
  </div>

  <p-button
    icon="pi pi-ellipsis-v"
    [text]="true"
    (click)="updateMenu(); menu.toggle($event)"
  />
</div>
}

<p-menu #menu [model]="items" [popup]="true" />

<app-select-agent
  [(visible)]="dialogVisible"
  (agentSelected)="onAgentSelected($event)"
  (agentRemoved)="onRemoveAgent()"
/>
