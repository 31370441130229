import { Component, EventEmitter, Input, Output } from '@angular/core';
import { menuItems } from './items';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-menu-items',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './menu-items.component.html',
})
export class MenuItemsComponent {
  @Output() itemClicked = new EventEmitter<void>();
  menuItems = menuItems;

  onMenuItemClick() {
    this.itemClicked.emit();
  }
}
