import { Component, inject, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'app-header-pages',
  imports: [ButtonModule, DividerModule],
  standalone: true,
  templateUrl: './header-pages.component.html',
})
export class HeaderPagesComponent {
  protected readonly headerService = inject(HeaderService);
}
