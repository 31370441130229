import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Status } from '../../utils/status';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  private statusSubject = new BehaviorSubject<Status>(Status.NOT_STARTED);
  status$ = this.statusSubject.asObservable();

  getItems() {
    return this.status$.pipe(
      map((status) => [
        {
          label: 'No iniciado',
          icon: 'pi pi-clock',
          command: () => {
            this.updateStatus(Status.NOT_STARTED);
          },
          disabled: status === Status.NOT_STARTED,
        },
        {
          label: 'Pausar',
          icon: 'pi pi-pause',
          command: () => {
            this.updateStatus(Status.PAUSED);
          },
          disabled: status === Status.PAUSED,
        },
        {
          label: 'Iniciar',
          icon: 'pi pi-play',
          command: () => {
            this.updateStatus(Status.INITIALIZED);
          },
          disabled: status === Status.INITIALIZED,
        },
        {
          label: 'Finalizar',
          icon: 'pi pi-check',
          command: () => {
            this.updateStatus(Status.FINISHED);
          },
          disabled: status === Status.FINISHED,
        },
      ])
    );
  }

  updateStatus(status: Status): void {
    this.statusSubject.next(status);
  }

  getCurrentStatus(): Status {
    return this.statusSubject.value;
  }
}
