import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { UserLogin } from '../interfaces/userLogin';
import { BehaviorSubject, delay, map, Observable, tap } from 'rxjs';
import { BASEURL, LOGIN } from '../constant/url';
import { Router } from '@angular/router';
import { User } from '../interfaces/user';
import { TOKEN } from '../constant/storage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _http = inject(HttpClient);
  private readonly _router = inject(Router);

  private readonly _role = signal<string | null>(null);
  private readonly _roleSubject = new BehaviorSubject<string | null>(null);

  constructor() {
    if (localStorage.getItem(TOKEN)) {
      this.initializeUserRole();
    }
  }

  private initializeUserRole(): void {
    if (this._role() !== null) {
      this._roleSubject.next(this._role());
    } else {
      this.fetchUserRole();
    }
  }

  private fetchUserRole(): void {
    this._http
      .get<User>(BASEURL + 'users/me?populate=role')
      .pipe(
        map((response: User) => response.role?.id || null),
        tap((role: string | null) => {
          this._role.set(role);
          this._roleSubject.next(role);
        })
      )
      .subscribe();
  }

  fetchUser(): Observable<User> {
    return this._http.get<User>(BASEURL + 'users/me?populate=role');
  }

  getRoleObservable(): Observable<string | null> {
    return this._roleSubject.asObservable();
  }

  login(data: UserLogin): Observable<any> {
    return this._http.post(LOGIN, data).pipe(
      tap((res: any) => {
        this.doLoginUser(res.jwt, res.user.username, res.user.email);
        this.initializeUserRole();
      })
    );
  }

  private doLoginUser(token: string, username: string, email: string) {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem('username', username);
    localStorage.setItem('email', email);
  }

  logout() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    this._role.set(null);
    this._roleSubject.next(null);
    this._router.navigate(['/login']);
  }

  isLoggedIn() {
    return localStorage.getItem(TOKEN);
  }

  chagePassword(
    password: string,
    currentPassword: string,
    passwordConfirmation: string
  ): Observable<any> {
    return this._http.post(BASEURL + 'auth/change-password', {
      password,
      currentPassword,
      passwordConfirmation,
    });
  }
}
