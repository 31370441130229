<form (ngSubmit)="onSubmit()" [formGroup]="formNote">
  <div class="grid">
    <div class="col-12 lg:col-6 flex-column">
      <div class="field">
        <label for="notes" class="font-semibold">Nota</label>
        <textarea
          pInputTextarea
          class="col-12"
          [rows]="5"
          formControlName="note"
          name="notes"
          id="notes"
        ></textarea>
      </div>
      <div class="flex justify-content-end">
        <p-button icon="pi pi-save" label="Guardar" type="submit" />
      </div>
    </div>
    <div class="col-12 lg:col-6 flex-column mt-2">
      @if (notes.length === 0) {
      <p-messages
        [(value)]="messages"
        escape="false"
        [closable]="false"
        [enableService]="false"
      />
      }
      <p-scroller [items]="notes" [scrollHeight]="'300px'">
        <ng-template pTemplate="item" let-note>
          <div
            class="flex-column mt-3 shadow-1 p-3 border-round overflow-hidden text-overflow-ellipsis hover:surface-hover"
          >
            <div class="flex justify-content-between align-items-center">
              <small class="text-color-secondary">
                <i class="pi pi-fw pi-calendar mr-2"></i>
                {{ note.attributes.createdAt | date : "dd/MM/yyyy HH:mm" }}
              </small>

              <p-button
                class="p-0 m-0"
                icon="pi pi-ellipsis-v"
                text="true"
                (click)="selectedItem = note; menu.toggle($event)"
              />
            </div>

            <span> {{ note.attributes.description }}</span>
          </div>
        </ng-template>
      </p-scroller>

      <p-menu #menu [model]="items" [popup]="true" />
      <p-dialog
        header="Editar nota"
        [(visible)]="editDialogVisible"
        [modal]="true"
        [style]="{ width: '50vw' }"
        (onHide)="editDialogVisible = false"
      >
        <div class="field">
          <label for="notes" class="font-semibold">Nota</label>
          <textarea
            pInputTextarea
            class="col-12"
            formControlName="editNote"
            name="notes"
            id="notes"
          ></textarea>
        </div>
        <ng-template pTemplate="footer">
          <p-button
            label="Cancelar"
            severity="secondary"
            (onClick)="editDialogVisible = false"
          />
          <p-button label="Aceptar" (onClick)="updateNote()" />
        </ng-template>
      </p-dialog>
    </div>
  </div>
</form>

<p-dialog
  [(visible)]="infoSidebarVisible"
  header="Información"
  [modal]="true"
  [style]="{ width: '50vw' }"
  (onHide)="infoSidebarVisible = false"
>
  <div class="flex flex-column gap-2">
    <span>
      <i class="pi pi-fw pi-user pr-4"></i>

      <span>
        {{ infoNote().userCreated }}
      </span>
    </span>
    <span>
      <i class="pi pi-fw pi-calendar-plus pr-4"></i>

      <span>
        {{ infoNote().createdAt | date : "dd/MM/yyyy HH:mm" }}
      </span>
    </span>

    @if (infoNote().updatedAt) {
    <span>
      <i class="pi pi-fw pi-calendar-clock pr-4"></i>

      <span>
        {{ infoNote().updatedAt | date : "dd/MM/yyyy HH:mm" }}
        > {{ infoNote().userUpdated }}
      </span>
    </span>
    }
  </div>
</p-dialog>
