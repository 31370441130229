<div class="flex align-items-center justify-content-between pb-3 md:flex-row">
  <div class="flex align-items-center">
    @if (headerService.backIsVisible) {
    <p-button
      icon="pi pi-arrow-left"
      (onClick)="headerService.back()"
      [rounded]="true"
      [text]="true"
      severity="secondary"
      size="small"
    />
    <p-divider layout="vertical" class="p-0" />

    }
    <span class="p-4 font-semibold">{{ headerService.title }}</span>
  </div>
</div>
