import { Component, EventEmitter, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AlertsComponent } from '../alerts/alerts.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [AlertsComponent, ButtonModule],
  templateUrl: './navbar.component.html',
})
export class NavbarComponent {
  @Output() toggleSidebar = new EventEmitter<void>();

  toggleMenu() {
    this.toggleSidebar.emit();
  }
}
