<app-data-table
  [columns]="columns"
  [data]="agentsTable()"
  [filters]="filters"
  [totalRecords]="totalRecords()"
  (add)="onAdd()"
  (pageChange)="onPage($event)"
  (edit)="onEdit($event)"
  (removeSelected)="onRemoveSelected($event)"
/>

<app-add-agent [(visible)]="dialogVisible" (save)="onSave($event)" />
