import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { UserLogin } from '../../interfaces/userLogin';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { FormUtilitiesService } from '../../services/from.utilitilies.service';

@Component({
  selector: 'app-login-form',
  standalone: true,

  imports: [CardModule, ButtonModule, ReactiveFormsModule, InputComponent],
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent implements OnInit {
  @Input() loading = false;
  @Output() loginEvent = new EventEmitter<UserLogin>();
  private _fb = inject(FormBuilder);
  protected formUtilities = inject(FormUtilitiesService);
  isDisabled = false;
  formLogin!: FormGroup;

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm() {
    this.formLogin = this._fb.group({
      identifier: [''],
      password: [''],
    });
  }

  protected onSubmit(): void {
    if (this.formLogin.valid) {
      this.loginEvent.emit(this.formLogin.value);
    }
  }
}
