export enum Status {
  NOT_STARTED = 'NOT_STARTED',
  INITIALIZED = 'INITIALIZED',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED',
}

export const transformStatus = (status: string) => {
  switch (status) {
    case Status.NOT_STARTED:
      return 'No iniciado';
    case Status.INITIALIZED:
      return 'Iniciado';
    case Status.PAUSED:
      return 'Pausado';
    case Status.FINISHED:
      return 'Finalizado';
    default:
      return status;
  }
};

export function transformServerity(
  status: any
):
  | 'success'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'danger'
  | 'contrast'
  | undefined {
  switch (status) {
    case Status.NOT_STARTED:
      return 'secondary';

    case Status.INITIALIZED:
      return 'info';
    case Status.PAUSED:
      return 'warning';
    case Status.FINISHED:
      return 'success';
    default:
      return 'contrast';
  }
}
