<div class="grid flex-row">
  <div class="col-6">
    <p-skeleton width="100%" height="3rem" />
  </div>

  <div class="col-6">
    <p-skeleton width="100%" height="3rem" />
  </div>
  <div class="col-6">
    <p-skeleton width="100%" height="6rem" />
  </div>

  <div class="col-6">
    <p-skeleton width="100%" height="6rem" />
  </div>
</div>
