<nav
  class="flex px-4 align-items-center justify-content-end h-4rem shadow-2 surface-50"
>
  <div class="lg:hidden">
    <p-button
      class="block"
      icon="pi pi-bars"
      [text]="true"
      [outlined]="true"
      (click)="toggleMenu()"
    />
  </div>
</nav>
