import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BASEURL } from '../constant/url';
import { Client, ClientAttributes, Clients } from '../interfaces/client';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly _http = inject(HttpClient);

  fetchClients(page: number, pageSize?: number): Observable<Clients> {
    return this._http.get<Clients>(
      `${BASEURL}clients?pagination[page]=${page}&pagination[pageSize]=${
        pageSize || 10
      }`
    );
  }
  // REFACTORING
  getAll(): Observable<Clients> {
    return this._http.get<Clients>(BASEURL + 'clients');
  }

  fetchClient(id: number): Observable<Client> {
    return this._http
      .get<Client>(`${BASEURL}clients/${id}?populate=*`)
      .pipe(map((response: Client) => response.data));
  }

  addClient(data: Client): Observable<Client> {
    return this._http.post<Client>(`${BASEURL}clients`, { data });
  }

  updateClient(id: number, data: any): Observable<Client> {
    return this._http
      .put<Client>(`${BASEURL}clients/${id}?populate=*`, {
        data,
      })
      .pipe(map((response: Client) => response.data));
  }

  deleteClient(id: number): Observable<any> {
    return this._http.delete<any>(`${BASEURL}clients/${id}`);
  }
}
