export const menuItems = [
  {
    label: 'Administración',
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        routerLink: 'dashboard',
      },
      {
        label: 'Clientes',
        icon: 'pi pi-briefcase',
        routerLink: 'clients',
      },
      {
        label: 'Empleados',
        icon: 'pi pi-fw pi-users',
        routerLink: 'employees',
      },
      {
        label: 'Categorías',
        icon: 'pi pi-fw pi-tag',
        routerLink: 'categories',
      },
    ],
  },
  {
    label: 'Operaciones',
    items: [
      {
        label: 'Servicios',
        icon: 'pi pi-fw pi-hammer',
        routerLink: 'services',
      },
      {
        label: 'Presupuestos',
        icon: 'pi pi-fw pi-clipboard',
        routerLink: 'budgets',
      },
      {
        label: 'Agentes',
        icon: 'pi pi-fw pi-id-card',
        routerLink: 'agents',
      },
    ],
  },
  {
    label: 'Configuración', // Nueva sección
    items: [
      {
        label: 'Ajustes',
        icon: 'pi pi-fw pi-cog',
        routerLink: 'settings',
      },
    ],
  },
];
