<div class="grid align-items-center justify-content-center">
  <div class="md:col-6 col-12">
    <div
      clas
      class="flex align-items-center gap-2 shadow-1 justify-content-between p-3 surface-card border-round-md"
    >
      <span> Modo Oscuro </span>
      <p-inputSwitch
        [(ngModel)]="darkMode"
        (ngModelChange)="themeService.toggleTheme()"
      >
      </p-inputSwitch>
    </div>
  </div>
</div>
