import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { Agent } from '../../interfaces/agent';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SelectAgentComponent } from './select-agent/select-agent.component';
import { MenuModule } from 'primeng/menu';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { MessagesModule } from 'primeng/messages';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';

@Component({
  selector: 'app-agent',
  standalone: true,
  imports: [
    ButtonModule,
    DividerModule,
    SelectAgentComponent,
    MenuModule,
    MessagesModule,
    AvatarModule,
    BadgeModule,
  ],
  templateUrl: './agent.component.html',
})
export class AgentComponent implements OnInit {
  @Input() agent!: Agent;
  @Output() agentSelected = new EventEmitter<number>();
  @Output() agentRemoved = new EventEmitter();

  protected messages = signal<Message[]>([]);
  private readonly _router = inject(Router);
  protected items: MenuItem[] = [];
  protected isAgent = false;
  protected dialogVisible = false;

  ngOnInit(): void {
    this.initialMessage();
  }

  private initialMessage() {
    this.messages.set([
      {
        severity: 'info',
        summary: 'Sin Agente.',
      },
    ]);
  }

  addAgent() {
    this.dialogVisible = true;
  }

  onAgentSelected(agent: any): void {
    this.agent = agent;

    this.agentSelected.emit(agent.id);
    this.dialogVisible = false;
  }

  private onSeeAgent(): void {
    this._router.navigate(['agents/' + this.agent.data.id]);
  }

  private callAgent() {
    const { phone } = this.agent.attributes;
    if (phone) {
      window.location.href = `tel:${phone}`;
    }
  }

  private sendEmail() {
    const { email } = this.agent.attributes;
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  }

  onRemoveAgent() {
    this.agentRemoved.emit();
  }

  updateMenu() {
    this.items = [
      {
        label: 'Acciones',
        items: [
          {
            label: 'Ver',
            icon: 'pi pi-eye',
            command: () => this.onSeeAgent(),
          },
          {
            label: 'Llamar',
            icon: 'pi pi-phone',
            command: () => this.callAgent(),
            disabled: this.isPhoneDisabled,
          },
          {
            label: 'Email',
            icon: 'pi pi-at',
            command: () => this.sendEmail(),
            disabled: this.isEmailDisabled,
          },
          {
            separator: true,
          },
          {
            label: 'Eliminar',
            icon: 'pi pi-trash',
            command: () => this.onRemoveAgent(),
            styleClass: 'menu-item-danger',
          },
        ],
      },
    ];
  }

  private get isPhoneDisabled(): boolean {
    const { phone } = this.agent.data.attributes;
    return phone === null || phone === '';
  }

  private get isEmailDisabled(): boolean {
    const { email } = this.agent.data.attributes;
    return email === null || email === '';
  }
}
