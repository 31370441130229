<p-toolbar styleClass="gap-2 mb-4">
  <ng-template pTemplate="left">
    <div class="flex gap-4">
      <div class="flex flex-column gap-1 align-items-start">
        <span class="text-sm font-semibold">Fecha de alta:</span>
        <span class="text-xs font-light">{{
          createdAt | date : "dd/MM/yyyy HH:mm"
        }}</span>
      </div>
      <div class="flex flex-column gap-1 align-items-start">
        <span class="text-sm font-semibold">Última modificación:</span>
        <span class="text-xs font-light"
          >{{ updatedAt | date : "dd/MM/yyyy HH:mm" }}
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="right">
    <div class="flex gap-2">
      <p-button
        icon="pi pi-trash"
        label="Eliminar"
        [severity]="'danger'"
        [outlined]="true"
        (onClick)="handleDelete()"
      />
      <p-button icon="pi pi-pencil" label="Editar" (onClick)="handleEdit()" />
    </div>
  </ng-template>
</p-toolbar>
