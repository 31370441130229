import { Component, inject, OnInit, signal } from '@angular/core';
import { CrudOperations } from '../../interfaces/crud_table';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BudgetService } from '../../services/budget.service';
import { HeaderService } from '../../services/header.service';
import {
  BudgetAttributes,
  BudgetPayload,
  Budgets,
  BudgetsDataTable,
} from '../../interfaces/budget';
import {
  Column,
  DataTableComponent,
} from '../../components/data-table/data-table.component';

import { AddBudgetComponent } from './add-budget/add-budget.component';
import { MediaService } from '../../services/media.service';
import { forkJoin } from 'rxjs';
import { formatDateOrDefault } from '../../utils/date';

@Component({
  selector: 'app-budgets',
  standalone: true,
  imports: [DataTableComponent, AddBudgetComponent],
  templateUrl: './budgets.component.html',
})
export class BudgetsComponent implements OnInit, CrudOperations<any> {
  private readonly _headerService = inject(HeaderService);
  private readonly _budgetService = inject(BudgetService);
  private readonly _mediaService = inject(MediaService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _router = inject(Router);
  protected budgets = signal<Budgets>({} as Budgets);
  protected budgetsDataTable = signal<BudgetsDataTable[]>([]);
  protected totalRecords = signal<number>(0);
  protected dialogVisible = false;
  protected loading = signal<boolean>(false);

  protected columns: Column = [
    { field: 'id', header: 'ID', sortable: true },
    { field: 'address', header: 'Dirección' },
    { field: 'createAt', header: 'Fecha de creación', sortable: true },
    { field: 'updatedAt', header: 'Fecha de actualización', sortable: true },
    { field: 'startAt', header: 'Fecha de inicio', sortable: true },
  ];

  protected filters: string[] = ['description', 'address', 'startAt'];

  ngOnInit(): void {
    this._headerService.setHeader('Lista de Presupuestos', false);
    this.loadBudgets();
  }

  private loadBudgets(page: number = 1, pageSize: number = 10): void {
    this.loading.set(true);
    this._budgetService.fetchBudgets(page, pageSize).subscribe({
      next: (budgets) => {
        this.budgets.set(budgets);
        this.totalRecords.set(budgets.meta.pagination.total || 0);

        this.budgetsDataTable.set(
          this.budgets().data.map((budget) => ({
            id: budget.id,
            createAt: formatDateOrDefault(budget.attributes.createdAt) || '',
            updatedAt: formatDateOrDefault(budget.attributes.updatedAt) || '',
            publishedAt:
              formatDateOrDefault(budget.attributes.publishedAt) || '',
            address: budget.attributes.address || '',
            description: budget.attributes.description || '',
            startAt: formatDateOrDefault(budget.attributes.startAt) || '',
          }))
        );
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Presupuestos no encontrados',
          detail: 'Ha ocurrido un error al obtener los presupuestos',
        });
      },
      complete: () => {
        this.loading.set(false);
      },
    });
  }

  onAdd(): void {
    this.dialogVisible = true;
  }

  onEdit(item: any): void {
    this._router.navigate(['/budgets', item.id]);
  }
  onRemoveSelected(items: any[]): void {
    this.removeBudgets(items.map((item) => item.id));
  }

  onSave({
    budgetData,
    files,
  }: {
    budgetData: BudgetAttributes;
    files: File[];
  }): void {
    const createBudget = (fileIds: number[] = []) => {
      const postData: BudgetPayload = {
        ...budgetData,
        media: fileIds,
        client: budgetData.selectedClient,
      };

      if (!budgetData.startAt) {
        delete postData.startAt;
      }

      this._budgetService.addBudget(postData).subscribe({
        next: () => {
          this._messageService.add({
            severity: 'success',
            summary: 'Presupuesto creado',
            detail: 'Presupuesto creado correctamente',
          });
          this.loadBudgets();
          this.dialogVisible = false;
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Presupuesto no creado',
            detail: 'Ha ocurrido un error al crear el presupuesto',
          });
        },
      });
    };

    if (files && files.length > 0) {
      this._mediaService.uploadFiles(files).subscribe({
        next: (uploadResponse) => {
          const fileIds = uploadResponse.map((file: any) => file.id);
          createBudget(fileIds);
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error al subir archivos',
            detail: 'Ha ocurrido un error al subir los archivos',
          });
        },
      });
    } else {
      createBudget();
    }
  }

  onPage(event: { page: number; rows: number }): void {
    const page = event.page + 1;
    const pageSize = event.rows;
    this.loadBudgets(page, pageSize);
  }

  private removeBudgets(ids: number[]): void {
    this._confirmDialog.confirm({
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      message: '¿Deseas eliminar los presupuestos seleccionados?',
      accept: () => {
        const deleteRequest = ids.map((id) =>
          this._budgetService.deleteBudget(id)
        );
        forkJoin(deleteRequest).subscribe({
          next: () => {
            this.ngOnInit();
            this._messageService.add({
              severity: 'success',
              summary: 'Presupuestos eliminados',
              detail: 'Presupuestos eliminados correctamente',
            });
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Presupuestos no eliminados',
              detail: 'Ha ocurrido un error al eliminar los presupuestos',
            });
          },
        });
      },
    });
  }
}
