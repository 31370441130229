/**
 * Formatea una fecha en base a un string de fecha.
 * Si la fecha es nula, indefinida o inválida, retorna un valor por defecto.
 *
 * @param dateString - La cadena de fecha a formatear.
 * @param defaultText -  (Opcional) El texto por defecto que se devolverá si la fecha es inválida o nula.
 * @param locale - (Opcional) El locale para formatear la fecha. Por defecto es 'es-ES'.
 * @returns La fecha formateada o el texto por defecto.
 */
export function formatDateOrDefault(
  dateString: string | null | undefined,
  defaultText: string = '',
  locale: string = 'es-ES'
): string {
  if (!dateString) {
    return defaultText;
  }

  const date = new Date(dateString);

  // Validamos si la fecha es válida
  if (isNaN(date.getTime())) {
    return defaultText;
  }

  return date.toLocaleDateString(locale);
}

/**
 * Convierte una fecha a formato ISO 8601 (YYYY-MM-DD) sin información de zona horaria.
 * @param date - La fecha que se desea convertir.
 * @returns La fecha en formato ISO 8601 (YYYY-MM-DD).
 */
export function convertToIsoDateString(
  date: Date | string | undefined | null
): string | undefined {
  if (!date) {
    return undefined; // No se incluye en el payload si es null, undefined o una cadena vacía
  }
  const dateObj = typeof date === 'string' ? new Date(date) : date;

  // Ajusta la fecha a UTC y luego devuelve solo la parte de la fecha (YYYY-MM-DD)
  return new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 10);
}
