import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ContactPerson } from '../../../interfaces/contact_people';
import { InputComponent } from '../../input/input.component';
@Component({
  selector: 'app-add-contact',
  standalone: true,
  imports: [
    DialogModule,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    InputComponent,
  ],
  templateUrl: './add-contact.component.html',
})
export class AddContactComponent implements OnInit, OnChanges {
  @Input() visible = false;
  @Input() idAgent: number | undefined;
  @Input() idClient: number | undefined;
  @Input() idBudget: number | undefined;
  @Input() idService: number | undefined;
  @Input() contactToEdit: ContactPerson | null = null;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();

  private readonly _fb = inject(FormBuilder);
  protected formContact!: FormGroup;

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm(): void {
    this.formContact = this._fb.group({
      name: [''],
      email: '',
      phone: '',
      observation: '',
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contactToEdit'] && this.contactToEdit) {
      this.updateFormValues();
    }

    if (changes['visible'] && this.visible) {
      this.resetForm();
      if (this.contactToEdit) {
        this.updateFormValues();
      }
    }
  }

  private updateFormValues(): void {
    if (this.contactToEdit?.attributes) {
      const { name, email, phone, observation } = this.contactToEdit.attributes;
      this.formContact.patchValue({ name, email, phone, observation });
    }
  }

  private resetForm(): void {
    this.formContact.reset();
  }

  onSubmit(): void {
    if (this.formContact.valid) {
      const formValue = this.formContact.value;
      const postData = {
        data: {
          name: formValue.name,
          email: formValue.email,
          phone: formValue.phone,
          observation: formValue.observation,
          agent: this.idAgent,
          client: this.idClient,
          budget: this.idBudget,
          service: this.idService,
        },
      };
      if (this.contactToEdit) {
        this.update.emit({ ...postData, id: this.contactToEdit.id });
      } else {
        this.save.emit(postData);
      }
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.formContact.reset();
  }

  get dialogHeader(): string {
    return this.contactToEdit ? 'Editar Contacto' : 'Añadir Contacto';
  }
}
