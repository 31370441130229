<ul class="list-none p-0">
  @for (section of menuItems; track $index) {
  <li class="m-2">
    <div class="p-menu-header">
      <span class="text-color-secondary">{{ section.label }}</span>
    </div>
    <ul class="list-none p-0">
      @for (item of section.items; track $index) {
      <li class="m-2">
        <a
          class="flex cursor-pointer align-items-center p-3 no-underline border-round-md"
          [routerLink]="[item.routerLink]"
          routerLinkActive="active-link"
          (click)="onMenuItemClick()"
          [style.color]="'inherit'"
        >
          <i class="{{ item.icon }} mr-2 text-primary"></i>
          <span>{{ item.label }}</span>
        </a>
      </li>
      }
    </ul>
  </li>
  }
</ul>
