<div class="field">
  <label for="employee" class="font-semibold">Empleados</label>
  <form [formGroup]="formEmployees">
    <p-multiSelect
      [options]="groupedEmployees()"
      [group]="true"
      formControlName="employes"
      optionLabel="label"
      optionValue="value"
      placeholder="Seleccione Empleado/s"
      appendTo="body"
      autowidth="false"
      [style]="{ minWidth: '15rem', width: '100%' }"
    />
  </form>
</div>
