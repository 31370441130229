<form (ngSubmit)="editAgent()" [formGroup]="formAgent">
  <app-toolbar
    [createdAt]="(agent().attributes ? agent().attributes.createdAt : '') || ''"
    [updatedAt]="(agent().attributes ? agent().attributes.updatedAt : '') || ''"
    (onEdit)="editAgent()"
    (onDelete)="deleteAgent()"
  />

  <p-tabView>
    <p-tabPanel header="Información" leftIcon="pi pi-info-circle">
      <div class="grid align-content-center justify-content-center col-12">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="name"
                formControlName="name"
                label="Nombre*"
                [customErrorMessages]="{
                  required: 'Este campo es requerido',
                }"
                type="text-required"
              />
            </div>
            <div class="col">
              <app-input
                name="surname"
                formControlName="surname"
                label="Apellidos"
              />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="email"
                formControlName="email"
                label="Email"
                type="email"
              />
            </div>
            <div class="col">
              <app-input
                name="phone"
                formControlName="phone"
                label="Teléfono"
                type="number"
              />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="phone_optional"
                formControlName="phone_optional"
                label="Teléfono (opcional)"
                type="number"
              />
            </div>
            <div class="col">
              <app-input
                name="province"
                formControlName="province"
                label="Provincia"
              />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="address"
                formControlName="address"
                label="Dirección"
              />
            </div>
            <div class="col">
              <app-input name="city" formControlName="city" label="Población" />
            </div>
          </div>
          <div class="formgrid grid">
            <div class="col">
              <app-input
                name="postal_code"
                formControlName="postal_code"
                label="Código postal"
                type="number-length"
                [minLength]="5"
                [customErrorMessages]="{
    minlength: 'Ingresa un Código postal valido',
              }"
              />
            </div>
            <div class="col">
              <app-input
                name="nif_cif"
                formControlName="nif_cif"
                label="NIF/CIF"
              />
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Contactos" leftIcon="pi pi-address-book">
      <app-contact-people
        [contacts]="contacts()"
        [idAgent]="idAgent()"
        (contactAdded)="onContactAdded($event)"
        (contactUpdated)="onContactUpdated($event)"
        (contactDeleted)="onContactDeleted($event)"
      />
    </p-tabPanel>

    <p-tabPanel header="Notas" leftIcon="pi pi-file-edit">
      <app-notes
        [notes]="notes()"
        [idAgent]="idAgent()"
        (onAddNote)="onAddNote($event)"
        (onEditNote)="onEditNote($event)"
        (onDeleteNote)="onDeleteNote($event)"
      />
    </p-tabPanel>
  </p-tabView>
</form>
