import { Component, inject } from '@angular/core';
import { LoginFormComponent } from '../../components/login-form/login-form.component';
import { UserLogin } from '../../interfaces/userLogin';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [LoginFormComponent, ToastModule],
  templateUrl: './login.component.html',
  providers: [MessageService],
})
export class LoginComponent {
  private readonly _router = inject(Router);
  private readonly _authService = inject(AuthService);
  private readonly _messageService = inject(MessageService);
  loading = false;

  protected handleFormSubmit(userLogin: UserLogin) {
    this.loading = true;
    this._authService.login(userLogin).subscribe({
      next: () => {
        this._router.navigateByUrl('/');
      },
      error: (error) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error.error.message,
        });
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
