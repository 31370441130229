import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CountTable } from '../interfaces/count_table';
import { BASEURL } from '../constant/url';
import { Operations } from '../interfaces/operation';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private readonly _http = inject(HttpClient);

  fetchCountTable(): Observable<CountTable> {
    return this._http.get<CountTable>(`${BASEURL}stats/count-tables`);
  }

  fetchOperations(): Observable<Operations[]> {
    return this._http.get<Operations[]>(`${BASEURL}stats/operations-by-month`);
  }
}
