import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { MediaFile } from '../../interfaces/media_file';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { URL } from '../../constant/url';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { MediaService } from '../../services/media.service';
import { UploadComponent } from '../upload/upload.component';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-media-viewer',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    CommonModule,
    DialogModule,
    DividerModule,
    TooltipModule,
    UploadComponent,
    MessagesModule,
  ],
  templateUrl: './media-viewer.component.html',
})
export class MediaViewerComponent implements OnInit {
  @Input() mediaFiles: MediaFile[] = [];
  @Output() onUpload = new EventEmitter<number[]>();
  @Output() onDelete = new EventEmitter<number>();

  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _mediaService = inject(MediaService);
  private readonly _messageService = inject(MessageService);
  private _selectedFiles: File[] = [];
  protected visibleModal = false;
  protected messages = signal<Message[]>([]);

  ngOnInit(): void {
    this.initialMessage();
  }

  private initialMessage() {
    this.messages.set([
      {
        severity: 'info',
        summary: 'Sin Archivos.',
      },
    ]);
  }

  getFileExtension(url: string): string {
    return url.split('.').pop() || '';
  }

  isImage(mime: string): boolean {
    return mime.startsWith('image/');
  }

  isPDF(mime: string): boolean {
    return mime.startsWith('application/pdf');
  }

  isVideo(mime: string): boolean {
    return mime.startsWith('video/');
  }

  isExcel(mime: string): boolean {
    return mime.startsWith('application/vnd.ms-excel');
  }

  getFullFileUrl(relativeUrl: string): string {
    return URL + relativeUrl;
  }

  openFile(file: MediaFile): void {
    const fullUrl = this.getFullFileUrl(file.attributes.url);
    window.open(fullUrl, '_blank');
  }

  downloadFile(file: MediaFile): void {
    const fileUrl = URL + file.attributes.url;

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.attributes.name;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al descargar el archivo, ' + error,
        });
      });
  }

  onFilesSelected(files: File[]): void {
    this._selectedFiles = files;
  }

  openModal(): void {
    this.visibleModal = true;
  }

  private normalizeFile(file: any): MediaFile {
    const {
      id,
      name,
      alternativeText = null,
      caption = null,
      width,
      height,
      formats,
      hash,
      ext,
      mime,
      size,
      url,
      previewUrl = null,
      provider,
      provider_metadata = null,
      createdAt,
      updatedAt,
    } = file;

    return {
      id,
      attributes: {
        id,
        name,
        alternativeText,
        caption,
        width,
        height,
        formats,
        hash,
        ext,
        mime,
        size,
        url,
        previewUrl,
        provider,
        provider_metadata,
        createdAt,
        updatedAt,
      },
    };
  }

  acceptModal(): void {
    this._mediaService.uploadFiles(this._selectedFiles).subscribe({
      next: (files) => {
        const normalizedFiles = files.map(this.normalizeFile.bind(this));
        const existingFilesMap = new Map<number, MediaFile>();
        this.mediaFiles.forEach((file) => existingFilesMap.set(file.id, file));
        normalizedFiles.forEach((newFile) => {
          existingFilesMap.set(newFile.id, newFile);
        });
        this.mediaFiles = Array.from(existingFilesMap.values());
        const filesIds = normalizedFiles.map((file: MediaFile) => file.id);
        this.onUpload.emit(filesIds);
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al cargar los archivos',
        });
      },
      complete: () => {
        this.closeModal();
      },
    });
  }

  closeModal(): void {
    this.visibleModal = false;
  }

  deleteFile(file: MediaFile): void {
    this._confirmDialog.confirm({
      message: '¿Estás seguro de eliminar el archivo?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this._mediaService.deleteFile(file).subscribe({
          next: () => {
            this.mediaFiles = this.mediaFiles.filter((f) => f.id !== file.id);
            this._messageService.add({
              severity: 'success',
              summary: 'Archivo eliminado',
              detail: 'Archivo eliminado correctamente',
            });
            this.onDelete.emit(file.id);
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar el archivo',
            });
          },
        });
      },
    });
  }
}
