<form (ngSubmit)="onSubmit()" [formGroup]="formCategory">
  <p-dialog
    header="{{ title }}"
    [modal]="true"
    [(visible)]="visible"
    [style]="{ width: '25vw' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    (onHide)="closeDialog()"
  >
    <span class="block mb-5 text-secondary">{{ subtitle }}</span>
    <div class="field col">
      <app-input
        label="Nombre*"
        [formControl]="formUtilities.getControl(formCategory, 'name')"
        [minLength]="3"
        [additionalValidators]="
          formUtilities.getValidators(formCategory, 'text')
        "
        [customErrorMessages]="{
          required: 'Este campo es requerido',
          minlength: 'El nombre debe tener al menos 3 caracteres'
        }"
        name="name"
        type="text-required"
      />
    </div>
    <small>*Campos obligatorios</small>

    <ng-template pTemplate="footer">
      <p-button
        label="Cancelar"
        severity="secondary"
        (onClick)="closeDialog()"
      />
      <p-button label="Aceptar" type="submit" />
    </ng-template>
  </p-dialog>
</form>
