<form [formGroup]="formGroup">
  <p-dialog
    header="Seleccionar Agente"
    [maximizable]="true"
    [(visible)]="visible"
    [style]="{ width: '30vw' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    (onHide)="closeDialog()"
    [modal]="true"
  >
    <span class="block mb-5 p-text-secondary"
      >Seleciona el agente de la lista</span
    >

    <p-listbox
      [options]="agents().data"
      optionLabel="attributes.name"
      formControlName="selectedAgent"
      [filter]="true"
      [style]="{ width: '100%' }"
    />

    <ng-template pTemplate="footer">
      <p-button
        label="Cancelar"
        severity="secondary"
        (onClick)="closeDialog()"
      />
      <p-button label="Aceptar" type="submit" (onClick)="acceptDialog()" />
    </ng-template>
  </p-dialog>
</form>
