import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Agent } from '../../../interfaces/agent';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { FormUtilitiesService } from '../../../services/from.utilitilies.service';
import { InputComponent } from '../../../components/input/input.component';

@Component({
  selector: 'app-add-agent',
  standalone: true,
  imports: [
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    DividerModule,
    InputComponent,
  ],
  templateUrl: './add-agent.component.html',
})
export class AddAgentComponent implements OnInit {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<Agent>();

  protected formUtilities = inject(FormUtilitiesService);
  private _fb = inject(FormBuilder);
  formAgent!: FormGroup;

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm() {
    this.formAgent = this._fb.group({
      name: [''],
      surname: [''],
      email: [''],
      phone: [''],
      phone_optional: [''],
      address: [''],
      city: [''],
      province: [''],
      postal_code: [''],
      nif_cif: [''],
    });
  }

  onSubmit(): void {
    if (this.formAgent.valid) {
      const formValue = this.formAgent.value;
      this.save.emit(formValue);
      this.closeDialog();
    }
  }
  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.formAgent.reset();
  }
}
