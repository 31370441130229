import { Component, inject, OnInit, signal } from '@angular/core';
import { StatsService } from '../../services/stats.service';
import { CountTable } from '../../interfaces/count_table';

@Component({
  selector: 'app-stats',
  standalone: true,
  imports: [],
  templateUrl: './stats.component.html',
})
export class StatsComponent implements OnInit {
  private readonly _coutTableService = inject(StatsService);

  countTable = signal<CountTable>({} as CountTable);
  ngOnInit(): void {
    this.loadCountTables();
  }

  private loadCountTables() {
    this._coutTableService.fetchCountTable().subscribe({
      next: (data) => {
        this.countTable.set(data);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
