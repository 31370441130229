import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { Attributes } from '../../../interfaces/services';
import { AuthService } from '../../../services/auth.service';
import { Roles } from '../../../utils/roles';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ClientSelectorComponent } from '../../../components/client-selector/client-selector.component';
import { InputComponent } from '../../../components/input/input.component';
import { UploadComponent } from '../../../components/upload/upload.component';
import { Client } from '../../../interfaces/client';
import { EmployeeSelectorComponent } from '../../../components/employee-selector/employee-selector.component';
import { User } from '../../../interfaces/user';
import { EmployeeListComponent } from '../../../components/employees-list/employee-list.component';

export interface ServiceData {
  serviceData: Attributes;
  files: File[];
}
@Component({
  selector: 'app-add-service',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    DividerModule,
    ClientSelectorComponent,
    UploadComponent,
    CalendarModule,
    InputNumberModule,
    InputComponent,
    EmployeeSelectorComponent,
    EmployeeListComponent,
  ],
  templateUrl: './add-service.component.html',
})
export class AddServiceComponent implements OnInit {
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<ServiceData>();

  private readonly _authService = inject(AuthService);
  private _selectedFiles: File[] = [];
  private _fb = inject(FormBuilder);
  protected formService!: FormGroup;

  private _visible = false;
  @Input()
  set visible(value: boolean) {
    this._visible = value;
    if (this._visible) {
    }
  }
  get visible(): boolean {
    return this._visible;
  }

  protected isAdmin = signal<boolean>(false);

  ngOnInit(): void {
    this.loadForm();
    this._authService.getRoleObservable().subscribe((role) => {
      this.isAdmin.set(String(role) === Roles.ADMIN);
    });
  }

  private loadForm(): void {
    this.formService = this._fb.group({
      client: [null],
      email: [''],
      name: [''],
      phone: [''],
      phone_optional: [''],
      province: [''],
      city: [''],
      address: [''],
      postal_code: [''],
      nif_cif: [''],
      description: [''],
      startAt: [''],
      import: [null],

      users_permissions_users: [null],
    });
  }

  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.formService.reset();
    this._selectedFiles = [];
  }

  onSubmit(): void {
    this.save.emit({
      serviceData: this.formService.getRawValue(),
      files: this._selectedFiles,
    });

    this.closeDialog();
  }

  onFilesSelected(files: File[]): void {
    this._selectedFiles = files;
  }

  onClientSelected(selectedClient: Client): void {
    if (!selectedClient) {
      this.formService.reset();
      this.formService.enable({ emitEvent: false });
      return;
    } else {
      this.formService.disable({ emitEvent: false });
      this.formService.get('startAt')?.enable();
      this.formService.get('description')?.enable();
      this.formService.get('import')?.enable();
      this.updateFormValues(selectedClient);
    }
  }

  onEmployeeSelected(employeesIds: User[]): void {
    this.formService.patchValue({ users_permissions_users: employeesIds });
  }

  private updateFormValues(client: Client): void {
    const {
      name,
      email,
      phone,
      phone_optional,
      province,
      city,
      address,
      postal_code,
      nif_cif,
    } = client.attributes;
    this.formService.patchValue({
      client: client.id,
      name,
      email,
      phone,
      phone_optional,
      province,
      city,
      address,
      postal_code,
      nif_cif,
    });
  }
}
