import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ContactPerson } from '../interfaces/contact_people';
import { BASEURL } from '../constant/url';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactPeopleService {
  private readonly _http = inject(HttpClient);

  add(data: any): Observable<ContactPerson> {
    return this._http.post<any>(`${BASEURL}contact-people`, data);
  }

  edit(data: ContactPerson, id: number): Observable<ContactPerson> {
    return this._http.put<ContactPerson>(
      `${BASEURL}contact-people/${id}`,
      data
    );
  }

  delete(id: number): Observable<any> {
    return this._http.delete<any>(`${BASEURL}contact-people/${id}`);
  }
}
