export type BooleanValue = { requiredLength: number };
export type ErrorFunction = (
  booleanValue?: BooleanValue,
  controlName?: string
) => string;

export const DefaultErrorMessages: Record<string, ErrorFunction> = {
  required: () => 'El campo es requerido',
  email: () => 'Dirección de correo electrónico no válida',
  pattern: () => 'El campo no es valido.',
  minlength: (booleanValue: BooleanValue = { requiredLength: 6 }) =>
    `El campo debe tener al menos ${booleanValue.requiredLength} caracteres.`,
  maxlength: (booleanValue: BooleanValue = { requiredLength: 50 }) =>
    `El campo no debe tener mas de ${booleanValue.requiredLength} caracteres.`,
  // ... other errors can be added here
};
