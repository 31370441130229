import { Injectable, signal } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private readonly _title = signal<string>('');
  private readonly _backIsVisible = signal<boolean>(false);

  get title() {
    return this._title();
  }

  get backIsVisible() {
    return this._backIsVisible();
  }

  setHeader(title: string, backIsVisible: boolean = false) {
    this._title.set(title);
    this._backIsVisible.set(backIsVisible);
  }

  back() {
    window.history.back();
  }
}
