import { Component, inject, OnInit, signal } from '@angular/core';
import { ServiceService } from '../../services/service.service';
import { Services } from '../../interfaces/services';
import { CommonModule } from '@angular/common';
import { Divider, DividerModule } from 'primeng/divider';
import { Router } from '@angular/router';

@Component({
  selector: 'app-last-services',
  standalone: true,
  imports: [CommonModule, DividerModule],
  templateUrl: './last-services.component.html',
})
export class LastServicesComponent implements OnInit {
  private readonly _serviceService = inject(ServiceService);
  private readonly _router = inject(Router);
  protected services = signal<Services>({} as Services);

  ngOnInit(): void {
    this._serviceService.fetchLastService().subscribe({
      next: (service) => {
        this.services.set(service);
      },
    });
  }

  goToServices(serviceId: number) {
    this._router.navigate(['services', serviceId]);
  }
}
