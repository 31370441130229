<div class="flex justify-content-end pb-3">
  <p-button
    [icon]="employees.data.length === 0 ? 'pi pi-plus' : 'pi pi-pencil'"
    [label]="employees.data.length === 0 ? 'Añadir' : 'Editar'"
    size="small"
    (onClick)="selectEmployees()"
    [text]="true"
    class="ml-auto"
  />
</div>
@if( employees.data.length === 0 ) {
<p-messages
  [(value)]="messages"
  escape="false"
  [closable]="false"
  [enableService]="false"
/>
}

<p-accordion [multiple]="true">
  @for (employee of employees.data; track $index) {
  <p-accordionTab header="{{ employee.attributes.name }}">
    @for (category of employee.attributes.categories?.data; track $index) {
    <app-tag [value]="category.attributes.name"></app-tag>
    }
  </p-accordionTab>
  }
</p-accordion>

<p-dialog
  header="Seleccionar empleados"
  [(visible)]="visible"
  [modal]="true"
  [maximizable]="true"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ width: '30vw' }"
  (onHide)="visible = false"
>
  <app-employee-selector
    (employeeSelected)="onEmployeeSelected($event)"
    [dialogVisible]="visible"
    [employeeIds]="employeesIds"
  />

  <ng-template pTemplate="footer">
    <p-button
      label="Cancelar"
      severity="secondary"
      (onClick)="visible = false"
    />
    <p-button label="Aceptar" (onClick)="addEmployees()" />
  </ng-template>
</p-dialog>
