import { Component, inject, OnInit, signal } from '@angular/core';
import { CrudOperations } from '../../interfaces/crud_table';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HeaderService } from '../../services/header.service';
import { ServiceService } from '../../services/service.service';
import {
  Column,
  DataTableComponent,
} from '../../components/data-table/data-table.component';
import { MediaService } from '../../services/media.service';
import {
  Attributes,
  Service,
  ServiceDataTable,
  Services,
} from '../../interfaces/services';
import { formatDateOrDefault } from '../../utils/date';
import { AddServiceComponent } from './add-service/add-service.component';
import { Status } from '../../utils/status';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [DataTableComponent, AddServiceComponent],
  templateUrl: './services.component.html',
})
export class ServicesComponent implements OnInit, CrudOperations<any> {
  private readonly _headerService = inject(HeaderService);
  private readonly _serviceService = inject(ServiceService);
  private readonly _mediaService = inject(MediaService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _router = inject(Router);

  protected services = signal<Services>({} as Services);
  protected servicesDataTable = signal<ServiceDataTable[]>([]);
  protected totalRecords = signal<number>(0);
  protected dialogVisible = false;
  protected loading = signal<boolean>(false);

  protected columns: Column = [
    { field: 'id', header: 'ID', sortable: true },
    { field: 'address', header: 'Dirección' },
    { field: 'createAt', header: 'Fecha de creación', sortable: true },
    { field: 'updatedAt', header: 'Fecha de actualización', sortable: true },
    { field: 'finishAt', header: 'Fecha de finalización', sortable: true },
    { field: 'status', header: 'Estado', sortable: true },
  ];

  protected filters: string[] = ['description', 'address', 'finishAt'];

  ngOnInit(): void {
    this._headerService.setHeader('Lista de servicios', false);
    this.loadServices();
  }

  private loadServices(page: number = 1, pageSize: number = 10): void {
    this._serviceService.fetchServices(page, pageSize).subscribe({
      next: (services) => {
        this.services.set(services);
        this.totalRecords.set(services.meta.pagination.total);
        this.servicesDataTable.set(
          this.services().data.map((service) => ({
            id: service.id,
            address: service.attributes.address,
            createAt: formatDateOrDefault(service.attributes.createdAt) || '',
            updatedAt: formatDateOrDefault(service.attributes.updatedAt) || '',
            finishAt: formatDateOrDefault(service.attributes.finishAt) || '',
            status: service.attributes.status || '',
          }))
        );
      },
    });
  }

  onAdd(): void {
    this.dialogVisible = true;
  }
  onEdit(item: any): void {
    this._router.navigate(['services', item.id]);
  }
  onRemoveSelected(items: any[]): void {
    throw new Error('Method not implemented.');
  }

  onPage(event: { page: number; rows: number }): void {
    const page = event.page + 1;
    const pageSize = event.rows;
    this.loadServices(page, pageSize);
  }
  onSave({
    serviceData,
    files,
  }: {
    serviceData: Attributes;
    files: File[];
  }): void {
    const createService = (fileIds: number[] = []) => {
      const postData = {
        ...serviceData,
        media: fileIds,
        client: serviceData.client,
      };

      if (!serviceData.startAt) {
        delete postData.startAt;
      }

      this._serviceService.addService(postData).subscribe({
        next: () => {
          this._messageService.add({
            severity: 'success',
            summary: 'Servicio creado',
            detail: 'Servicio creado correctamente',
          });
          this.loadServices();
          this.dialogVisible = false;
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Servicio no creado',
            detail: 'Ha ocurrido un error al crear el servicio',
          });
        },
      });
    };
    if (files && files.length > 0) {
      this._mediaService.uploadFiles(files).subscribe({
        next: (uploadResponse) => {
          const fileIds = uploadResponse.map((file: any) => file.id);
          createService(fileIds);
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error al subir archivos',
            detail: 'Ha ocurrido un error al subir los archivos',
          });
        },
      });
    } else {
      createService();
    }
  }
}
