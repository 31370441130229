<div class="surface-ground py-5 px-4">
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Servicios</span>
            <div class="text-900 font-medium text-xl">
              {{ countTable().services_count }}
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-blue-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-hammer text-blue-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">
          {{ countTable().services_count }}
        </span>
        <span class="text-500">Este último mes</span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Presupuestos</span>
            <div class="text-900 font-medium text-xl">
              {{ countTable().budgets_count }}
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-orange-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-clipboard text-orange-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">
          {{ countTable().budgets_last_month }}
        </span>
        <span class="text-500">Este último mes </span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Clientes</span>
            <div class="text-900 font-medium text-xl">
              {{ countTable().clients_count }}
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-cyan-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-briefcase text-cyan-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">
          {{ countTable().clients_last_month }}
        </span>
        <span class="text-500">Registros este mes </span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Agentes</span>
            <div class="text-900 font-medium text-xl">
              {{ countTable().agents_count }}
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-purple-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-id-card text-purple-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">
          {{ countTable().agents_last_month }}
        </span>
        <span class="text-500">Registros este mes</span>
      </div>
    </div>
  </div>
</div>
