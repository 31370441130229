import { Component, inject, OnInit, signal } from '@angular/core';
import {
  Column,
  DataTableComponent,
} from '../../components/data-table/data-table.component';
import { ButtonModule } from 'primeng/button';
import { HeaderService } from '../../services/header.service';
import { AgentService } from '../../services/agent.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Agent, Agents, AgentTable } from '../../interfaces/agent';
import { CrudOperations } from '../../interfaces/crud_table';
import { AddAgentComponent } from './add-agent/add-agent.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-agents',
  standalone: true,
  imports: [DataTableComponent, ButtonModule, AddAgentComponent],
  templateUrl: './agents.component.html',
})
export class AgentsComponent implements OnInit, CrudOperations<any> {
  private readonly _headerService = inject(HeaderService);
  private readonly _agentService = inject(AgentService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _router = inject(Router);
  protected agents = signal<Agents>({} as Agents);
  protected agentsTable = signal<AgentTable[]>([]);
  protected totalRecords = signal<number>(0);
  protected dialogVisible = false;

  protected columns: Column = [
    { field: 'id', header: 'ID', sortable: true },
    { field: 'name', header: 'Nombre', sortable: true },
    { field: 'address', header: 'Dirección' },
    { field: 'email', header: 'Email' },
    { field: 'phone', header: 'Telefóno' },
  ];

  protected filters: string[] = ['name', 'email', 'phone'];

  ngOnInit(): void {
    this._headerService.setHeader('Lista de Agentes', false);
    this.loadAgents();
  }

  private loadAgents(page: number = 1, pageSize: number = 10): void {
    this._agentService.fetchAgents(page, pageSize).subscribe({
      next: (agents) => {
        this.agents.set(agents);
        this.totalRecords.set(agents.meta.pagination.total);

        this.agentsTable.set(
          this.agents().data.map((agent: Agent) => ({
            id: agent.id,
            name: agent.attributes.name,
            address: agent.attributes.address || '',
            email: agent.attributes.email || '',
            phone: agent.attributes.phone || '',
          }))
        );
      },

      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al obtener los agentes',
        });
      },
    });
  }

  onAdd(): void {
    this.dialogVisible = true;
  }
  onEdit(item: any): void {
    this._router.navigate(['agents', item.id]);
  }

  onRemoveSelected(items: any[]): void {
    this.removeAgents(items.map((item) => item.id));
  }

  onSave(agentData: any): void {
    this.dialogVisible = false;

    this._agentService.addAgent(agentData).subscribe({
      next: () => {
        this.ngOnInit();
        this._messageService.add({
          severity: 'success',
          summary: 'Agente creado',
          detail: 'Agente creado correctamente',
        });
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Agente no creado',
          detail: 'Ha ocurrido un error al crear el agente',
        });
      },
    });
  }

  onPage(event: { page: number; rows: number }): void {
    const page = event.page + 1;
    const pageSize = event.rows;
    this.loadAgents(page, pageSize);
  }

  private removeAgents(ids: number[]) {
    this._confirmDialog.confirm({
      message: '¿Deseas eliminar los agentes seleccionados?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const deleteRequest = ids.map((id) =>
          this._agentService.deleteAgent(id)
        );
        forkJoin(deleteRequest).subscribe({
          next: () => {
            this.ngOnInit();
            this._messageService.add({
              severity: 'success',
              summary: 'Agentes eliminados',
              detail: 'Agentes eliminados correctamente',
            });
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Agentes no eliminados',
              detail: 'Ha ocurrido un error al eliminar los agentes',
            });
          },
        });
      },
    });
  }
}
