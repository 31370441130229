import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";


export const authGuard: CanActivateFn = () => {
    const authService = inject(AuthService)
    const routerService = inject(Router)
    if (!authService.isLoggedIn()) {
        routerService.navigate(['/login'])
        return false
    }
    return true
}

export const authRedirectGuard: CanActivateFn = () => {
    const authService = inject(AuthService)
    const routerService = inject(Router)
    if (authService.isLoggedIn()) {
        routerService.navigate(['/'])
        return false
    }
    return true
}
