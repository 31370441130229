import { Component, inject, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { StatsComponent } from '../../components/stats/stats.component';
import { ChartComponent } from '../../components/chart/chart.component';
import { LastBudgetsComponent } from '../../components/last-budgets/last-budgets.component';
import { LastServicesComponent } from '../../components/last-services/last-services.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    StatsComponent,
    ChartComponent,
    LastBudgetsComponent,
    LastServicesComponent,
  ],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  private readonly _headerService = inject(HeaderService);

  ngOnInit(): void {
    this._headerService.setHeader('Dashboard', false);
  }
}
