<div
  class="flex justify-content-center align-items-center min-h-screen background"
>
  <p-card
    [style]="{ width: '25rem' }"
    header="🔐 Inicio de sesión"
    subheader="Ingresa tus credenciales"
  >
    <form [formGroup]="formLogin" (ngSubmit)="onSubmit()">
      <div class="flex flex-column gap-3 p-4">
        <app-input
          label="Email"
          icon="pi pi-user"
          [formControl]="formUtilities.getControl(formLogin, 'identifier')"
          type="email"
          name="identifier"
          [customErrorMessages]="{
            required: 'Este campo es requerido',
            email: 'Ingresa un email valido'
          }"
        />
        <app-input
          label="Contraseña"
          icon="pi pi-lock"
          [formControl]="formUtilities.getControl(formLogin, 'password')"
          type="password"
          name="password"
          [customErrorMessages]="{
            required: 'La contraseña es obligatoria',
            minlength: 'La contrasñea debe tener al menos 6 caracteres'
          }"
        />
        <div class="pt-4">
          <button
            pButton
            pRipple
            label="Iniciar sessión"
            class="p-3 w-full"
            [loading]="loading"
          ></button>
        </div>
      </div>
    </form>
  </p-card>
</div>
