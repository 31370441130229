import { Component, inject, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, InputSwitchModule, FormsModule],
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {
  private readonly _headerService = inject(HeaderService);
  readonly themeService = inject(ThemeService);
  protected darkMode = false;

  ngOnInit(): void {
    this._headerService.setHeader('Ajustes', false);
    this.darkMode = this.themeService.isDarkMode;
  }
}
