<div class="flex justify-content-end">
  <p-button
    icon="pi pi-plus"
    label="Añadir"
    size="small"
    (onClick)="addContact()"
    [text]="true"
    class="ml-auto"
  />
</div>
@if (contacts.length === 0) {
<p-messages
  [(value)]="messages"
  escape="false"
  [closable]="false"
  [enableService]="false"
/>
} @for(contact of contacts; track $index) {

<div
  class="flex align-items-center justify-content-between hover:surface-hover border-round-md mx-2 p-3"
>
  <div class="flex align-items-center gap-2">
    <p-avatar
      label="{{ contact.attributes.name.substring(0, 1) }}"
      pBadge
      [badgeDisabled]="
        contact.attributes.observation === null ||
        contact.attributes.observation === ''
      "
    />
    <div class="flex flex-column justify-content-start gap-1">
      <span class="font-semibold text-color-secondary">{{
        contact.attributes.name
      }}</span>
      <span class="text-xs">{{ contact.attributes.email }}</span>
      <span class="text-xs">{{ contact.attributes.phone }}</span>
    </div>
  </div>

  <p-button
    icon="pi pi-ellipsis-v"
    [text]="true"
    (click)="selectedItem = contact; updateMenu(); menu.toggle($event)"
  />
</div>

}
<p-menu #menu [model]="items" [popup]="true" />

<app-add-contact
  [(visible)]="addDialogVisible"
  (save)="onSave($event)"
  (update)="onUpdate($event, selectedItem.id)"
  [idAgent]="idAgent"
  [idClient]="idClient"
  [idBudget]="idBudget"
  [idService]="idService"
  [contactToEdit]="editingContact"
/>

<p-dialog
  header="Observaciones"
  [(visible)]="observationDialogVisible"
  [modal]="true"
  [maximizable]="true"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ width: '25vw' }"
>
  <p class="mb-5">
    {{ observation() }}
  </p>
</p-dialog>
