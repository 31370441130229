import { Component, Input } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { transformServerity, transformStatus } from '../../utils/status';

@Component({
  selector: 'app-tag',
  standalone: true,
  imports: [TagModule],
  templateUrl: './tag.component.html',
})
export class TagComponent {
  @Input() value: any;

  transformServerity(status: any) {
    return transformServerity(status);
  }

  transformStatus(status: any): string | undefined {
    return transformStatus(status);
  }
}
