import { Component, inject, OnInit, signal } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { User } from '../../interfaces/user';
import { HeaderService } from '../../services/header.service';
import { InputComponent } from '../../components/input/input.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToolbarComponent } from '../../components/toolbar/toolbar.component';
import { UsersService } from '../../services/user.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ChagePasswordComponent } from '../../components/chage-password/chage-password.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    ToolbarComponent,
    DividerModule,
    ButtonModule,
    DialogModule,
    ChagePasswordComponent,
  ],
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  private readonly _authService = inject(AuthService);
  private readonly _userService = inject(UsersService);
  private readonly _headerService = inject(HeaderService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  protected user = signal<User>({} as User);

  private _fb = inject(FormBuilder);
  protected formUser!: FormGroup;

  protected visible = false;

  ngOnInit(): void {
    this._headerService.setHeader('Mi Perfil', false);

    this.loadForm();

    this._authService.fetchUser().subscribe((user) => {
      this.user.set(user);
      this.formUser.patchValue(user);
    });
  }

  private loadForm() {
    this.formUser = this._fb.group({
      name: [''],
      username: [''],
      email: [''],
      phone: [''],
    });
  }

  editUser(): void {
    this._userService
      .updateUser(this.user().id, this.formUser.value)
      .subscribe({
        next: (user) => {
          this.user.set(user);
          this._messageService.add({
            severity: 'success',
            summary: 'Usuario actualizado',
            detail: 'Usuario actualizado correctamente',
          });
        },
        error: () => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al actualizar el usuario',
          });
        },
      });
  }

  onSubmitChangePassword(changePasswordForm: any): void {
    if (changePasswordForm.formChangePassword.invalid) {
      changePasswordForm.formChangePassword.markAllAsTouched();
      return;
    }

    this._authService
      .chagePassword(
        changePasswordForm.formChangePassword.value.password,
        changePasswordForm.formChangePassword.value.currentPassword,
        changePasswordForm.formChangePassword.value.passwordConfirmation
      )
      .subscribe({
        next: () => {
          this.visible = false;
          this._messageService.add({
            severity: 'success',
            summary: 'Contraseña actualizada',
            detail: 'Contraseña actualizada correctamente',
          });
        },
        error: (err) => {
          console.error(err);
          this._messageService.add({
            severity: 'error',
            summary: 'Contraseña no actualizada',
            detail: err.error.error.message,
          });
        },
      });
  }

  deleteUser(): void {
    this._confirmDialog.confirm({
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      message: '¿Estas seguro de eliminar tu cuenta?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this._confirmDialog.close();
        this._userService.deleteUser(this.user().id).subscribe({
          next: () => {
            this._messageService.add({
              severity: 'success',
              summary: 'Cuenta eliminada',
              detail: 'Cuenta eliminada correctamente',
            });
            this._authService.logout();
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar la cuenta',
            });
          },
        });
      },
    });
  }
}
