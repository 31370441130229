import { Component, inject, OnInit, signal } from '@angular/core';
import { BudgetService } from '../../../services/budget.service';
import { HeaderService } from '../../../services/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Budget } from '../../../interfaces/budget';
import { ToolbarComponent } from '../../../components/toolbar/toolbar.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Client } from '../../../interfaces/client';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { Message } from 'primeng/api';
import { ClientService } from '../../../services/client.service';
import { CalendarModule } from 'primeng/calendar';
import { convertToIsoDateString } from '../../../utils/date';
import { DialogModule } from 'primeng/dialog';
import { ClientSelectorComponent } from '../../../components/client-selector/client-selector.component';
import { MediaViewerComponent } from '../../../components/media-viewer/media-viewer.component';
import { MediaFile } from '../../../interfaces/media_file';
import { InputNumberModule } from 'primeng/inputnumber';
import { Roles } from '../../../utils/roles';
import { AuthService } from '../../../services/auth.service';
import { InputComponent } from '../../../components/input/input.component';
import { SkeltonComponent } from '../../../components/skelton/skelton.component';
import { TabViewModule } from 'primeng/tabview';
import { NotesComponent } from '../../../components/notes/notes.component';
import { Note } from '../../../interfaces/note';
import { ContactPerson } from '../../../interfaces/contact_people';
import { ContactPeopleComponent } from '../../../components/contact-people/contact-people.component';

@Component({
  selector: 'app-budget-details',
  standalone: true,
  imports: [
    TabViewModule,
    ToolbarComponent,
    ReactiveFormsModule,
    CardModule,
    InputTextareaModule,
    ButtonModule,
    MessagesModule,
    CalendarModule,
    DialogModule,
    ClientSelectorComponent,
    MediaViewerComponent,
    InputNumberModule,
    InputComponent,
    SkeltonComponent,
    NotesComponent,
    ContactPeopleComponent,
  ],
  templateUrl: './budget-details.component.html',
})
export class BudgetDetailsComponent implements OnInit {
  private readonly _headerService = inject(HeaderService);
  private readonly _activeRoute = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _budgetService = inject(BudgetService);
  private readonly _clientService = inject(ClientService);
  private readonly _authService = inject(AuthService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _fb = inject(FormBuilder);

  private readonly _filesIds = signal<number[]>([]);
  private readonly _clientSelected = signal<Client>({} as Client);

  protected budget = signal<Budget>({} as Budget);
  protected client = signal<Client>({} as Client);
  protected mediaFiles = signal<MediaFile[]>([]);
  protected contacts = signal<ContactPerson[]>([]);
  protected notes = signal<Note[]>([]);

  protected messages = signal<Message[]>([]);
  protected isAdmin = signal<boolean>(false);
  protected readonly idBudget = signal<number>(0);

  protected budgetForm!: FormGroup;
  protected visible = false;
  protected totalBudget = signal<number>(0);
  protected positionBudget = signal<number>(0);
  protected loading = signal<boolean>(false);
  ngOnInit(): void {
    this.loading.set(true);
    this._activeRoute.params.subscribe(({ id }) => {
      this.idBudget.set(id);
      this._headerService.setHeader(
        'Detalles del Presupuesto #' + id + '',
        true
      );
    });
    this.getRole();
    this.getBudget();
    this.loadForm();
    this.initialMessage();
  }

  private getRole(): void {
    this._authService.getRoleObservable().subscribe((role) => {
      this.isAdmin.set(String(role) === Roles.ADMIN);
    });
  }

  private getBudget(): void {
    this._budgetService.fetchBudget(this.idBudget()).subscribe({
      next: (budget) => {
        const { media, client, contact_people, notes } = budget.attributes;
        this.budget.set(budget);

        if (media.data) {
          const mediaFiles: MediaFile[] = media.data;
          this._filesIds.set(mediaFiles.map((file) => file.id));
          this.mediaFiles.set(mediaFiles);
        } else {
          this.mediaFiles.set([]);
        }

        if (client) {
          this.client.set(client.data);
        }

        if (contact_people) {
          this.contacts.set(contact_people.data);
        }

        if (notes) {
          this.notes.set(notes.data);
        }

        this.updateForm();
      },
      error: (error) => {
        this._messageService.add({
          severity: 'error',
          summary: 'Presupuesto no encontrado',
          detail: error.message,
        });
      },
      complete: () => {
        this.loading.set(false);
      },
    });
  }

  private initialMessage() {
    this.messages.set([
      {
        severity: 'info',
        summary: 'Cliente no registrado.',
        detail: 'Para registrar un nuevo cliente <u>pulse aquí</u>',
      },
    ]);
  }

  private loadForm() {
    this.budgetForm = this._fb.group({
      name: [''],
      email: [''],
      phone: [''],
      phone_optional: [''],
      address: [''],
      city: [''],
      province: [''],
      postal_code: [''],
      nif_cif: [''],
      startAt: [''],
      import: [0],
      observation: [''],
      description: [''],
    });
  }

  addClient(event: Event): void {
    event.preventDefault();
    if (this.budgetForm.invalid) {
      this.budgetForm.markAllAsTouched();
      return;
    }

    this._confirmDialog.confirm({
      header: 'Registro de cliente',
      icon: 'pi pi-info-circle',
      message: 'Se va ha registrar un nuevo cliente <br> ¿Deseas continuar?',
      accept: () => {
        this._clientService.addClient(this.budgetForm.getRawValue()).subscribe({
          next: (client: Client) => {
            this._budgetService
              .updateBudget(this.idBudget(), {
                client: client.data.id,
                ...client.data.attributes,
              })
              .subscribe({
                next: () => {
                  this.client.set(client.data);
                  this.updateForm();
                  this._messageService.add({
                    severity: 'success',
                    summary: 'Cliente registrado',
                    detail: 'Cliente registrado correctamente',
                  });
                },
                error: () => {
                  this._messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error al registrar el cliente',
                  });
                },
              });
          },
        });
      },
    });
  }

  private updateForm() {
    const budget = this.budget()?.attributes;
    const client = this.client()?.attributes;

    const startAtDate = budget?.startAt ? new Date(budget.startAt ?? '') : '';

    if (client) {
      this.disabledClientForm();
    } else {
      this.budgetForm.enable();
    }

    this.budgetForm.patchValue({
      name: client?.name ?? budget?.name,
      email: client?.email ?? budget?.email,
      phone: client?.phone ?? budget?.phone,
      phone_optional: client?.phone_optional ?? budget?.phone_optional,
      province: client?.province ?? budget?.province,
      city: client?.city ?? budget?.city,
      address: client?.address ?? budget?.address,
      postal_code: client?.postal_code ?? budget?.postal_code,
      nif_cif: client?.nif_cif ?? budget?.nif_cif,
      observation: budget?.observation,
      description: budget?.description,
      startAt: startAtDate ? startAtDate : '',
      import: budget?.import,
    });
  }

  editBudget(isMedia: boolean = false) {
    if (this.budgetForm.invalid) {
      this.budgetForm.markAllAsTouched();
      return;
    }

    let payload = {};
    if (isMedia) {
      payload = { media: this._filesIds() };
    } else {
      const {
        startAt,
        email,
        phone,
        phone_optional,
        province,
        address,
        city,
        postal_code,
        nif_cif,
        ...budgetData
      } = this.budgetForm.getRawValue();

      payload = {
        ...budgetData,
        client: this.client()?.id ?? null,
        startAt: startAt ? convertToIsoDateString(startAt) : null,
        email: email ?? null,
        phone: phone ?? null,
        phone_optional: phone_optional ?? null,
        province: province ?? null,
        address: address ?? null,
        city: city ?? null,
        postal_code: postal_code ?? null,
        nif_cif: nif_cif ?? null,
      };
    }

    this._budgetService.updateBudget(this.idBudget(), payload).subscribe({
      next: () =>
        this._messageService.add({
          severity: 'success',
          summary: 'Presupuesto actualizado',
          detail: 'Presupuesto actualizado correctamente',
        }),

      error: () =>
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al actualizar el presupuesto',
        }),
    });
  }

  editClient() {
    this.visible = true;
  }

  viewClient() {
    this._router.navigate(['/clients', this.client().id]);
  }

  closeDialog() {
    this.visible = false;
  }

  onClientSelected(selectedClient: Client): void {
    if (selectedClient) {
      this._clientSelected.set(selectedClient);
      this.client.set(selectedClient);
      this.disabledClientForm();
    }
  }

  private disabledClientForm() {
    this.budgetForm.disable({ emitEvent: false });
    this.budgetForm.get('startAt')?.enable();
    this.budgetForm.get('description')?.enable();
    this.budgetForm.get('import')?.enable();
  }

  updateClientBudget() {
    this.visible = false;

    const selectedClient = this._clientSelected();
    if (!selectedClient.id) {
      return;
    }

    this.client.set(selectedClient);
    this.updateForm();
  }

  onUpload(filesId: number[]) {
    if (!filesId) {
      return;
    }
    this._filesIds.update((ids) => [...ids, ...filesId]);
    this.editBudget(true);
  }

  onDelete(id: number) {
    if (!id) {
      return;
    }
    this._filesIds.update((ids) => ids.filter((fileId) => fileId !== id));
  }

  deleteBudget() {
    this._confirmDialog.confirm({
      header: 'Confirmación',
      message: '¿Está seguro de eliminar este presupuesto?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this._budgetService.deleteBudget(this.idBudget()).subscribe({
          next: () => {
            this._messageService.add({
              severity: 'success',
              summary: 'Presupuesto eliminado',
              detail: 'Presupuesto eliminado correctamente',
            });
            this._router.navigate(['/budgets']);
          },

          error: () =>
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar el presupuesto',
            }),
        });
      },
    });
  }

  removeClient() {
    this.client.set({} as Client);
    this.updateForm();
    this.budgetForm.get('name')?.reset();
    this.budgetForm.get('email')?.reset();
    this.budgetForm.get('phone')?.reset();
    this.budgetForm.get('phone_optional')?.reset();
    this.budgetForm.get('address')?.reset();
    this.budgetForm.get('city')?.reset();
    this.budgetForm.get('province')?.reset();
    this.budgetForm.get('postal_code')?.reset();
    this.budgetForm.get('nif_cif')?.reset();
  }

  onContactAdded(contact: any) {
    const newContact: ContactPerson = {
      id: contact.data.id,
      attributes: contact.data.attributes,
    };
    this.contacts().push(newContact);
  }

  onContactUpdated(contact: any) {
    this.contacts.update((contacts) => {
      const index = contacts.findIndex((c) => c.id === contact.data.id);
      if (index !== -1) {
        contacts[index] = {
          ...contacts[index],
          ...contact.data,
        };
      }
      return [...contacts];
    });
  }

  onContactDeleted(id: any) {
    this.contacts.update((contacts) =>
      contacts.filter((contact) => contact.id !== id)
    );
  }

  onAddNote(note: any) {
    this.notes.update((notes) =>
      [...notes, note.data].sort((a, b) => b.id - a.id)
    );
  }

  onEditNote($event: any) {
    this.notes.update((notes) => {
      const index = notes.findIndex((n) => n.id === $event.id);
      if (index !== -1) {
        notes[index] = {
          ...notes[index],
          ...$event,
        };
      }
      return [...notes];
    });
  }
  onDeleteNote($event: any) {
    this.notes.update((notes) => notes.filter((note) => note.id !== $event));
  }
}
