import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Agent, Agents } from '../../../interfaces/agent';
import { AgentService } from '../../../services/agent.service';
import { ListboxModule } from 'primeng/listbox';
import { ButtonModule } from 'primeng/button';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-agent',
  standalone: true,
  imports: [DialogModule, ListboxModule, ButtonModule, ReactiveFormsModule],
  templateUrl: './select-agent.component.html',
})
export class SelectAgentComponent implements OnInit {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() agentSelected = new EventEmitter<Agent>();
  formGroup!: FormGroup;
  private readonly _agentService = inject(AgentService);
  protected agents = signal<Agents>({} as Agents);

  ngOnInit(): void {
    this._agentService.fetchAgents(1, 100).subscribe({
      next: (agents) => {
        this.agents.set(agents);
      },
    });
    this.formGroup = new FormGroup({
      selectedAgent: new FormControl(),
    });
  }
  acceptDialog(): void {
    if (!this.formGroup.valid || !this.formGroup.value.selectedAgent) {
      return;
    }
    const selectedAgent: Agent = this.formGroup.value.selectedAgent;
    this.agentSelected.emit(selectedAgent);
    this.closeDialog();
  }

  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
