<app-data-table
  [columns]="columns"
  [data]="servicesDataTable()"
  [filters]="filters"
  [totalRecords]="totalRecords()"
  (add)="onAdd()"
  (edit)="onEdit($event)"
  (pageChange)="onPage($event)"
  (removeSelected)="onRemoveSelected($event)"
  [loading]="loading()"
/>

<app-add-service [(visible)]="dialogVisible" (save)="onSave($event)" />
