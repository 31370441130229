<div class="flex-column">
  <app-stats />
  <br />

  <div class="grid justify-content-around">
    <div class="col-12 md:col-6">
      <app-last-services class="col-12" />
    </div>

    <div class="col-12 md:col-6">
      <app-last-budgets class="col-12" />
    </div>
  </div>

  <br />
  <app-chart />
</div>
