import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { locale } from './utils/locale';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'admin-jtask';

  private primengConfig = inject(PrimeNGConfig);
  private themeService = inject(ThemeService);

  ngOnInit(): void {
    this.primengConfig.setTranslation(locale);
    this.themeService.initializeTheme();
  }
}
