import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Categories, Category } from '../interfaces/category';
import { map, Observable } from 'rxjs';
import { BASEURL } from '../constant/url';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private readonly _http = inject(HttpClient);

  fetchCategories(page: number, pageSize?: number): Observable<Categories> {
    return this._http.get<Categories>(
      `${BASEURL}categories?pagination[page]=${page}&pagination[pageSize]=${
        pageSize || 10
      }`
    );
  }

  addCategory(data: { data: { name: string } }): Observable<any> {
    return this._http.post<any>(BASEURL + 'categories', data);
  }

  deleteCategory(id: number): Observable<any> {
    return this._http.delete<any>(BASEURL + 'categories/' + id);
  }

  updateCategory(
    id: number,
    data: { data: { name: string } }
  ): Observable<any> {
    return this._http.put<any>(BASEURL + 'categories/' + id, data);
  }
}
