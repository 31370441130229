import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Client } from '../../../interfaces/client';
import { FormUtilitiesService } from '../../../services/from.utilitilies.service';
import { InputComponent } from '../../../components/input/input.component';

@Component({
  selector: 'app-add-client',
  standalone: true,
  imports: [
    DialogModule,
    InputComponent,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    DividerModule,
  ],
  templateUrl: './add-client.component.html',
})
export class AddClientComponent implements OnInit {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<Client>();

  protected formUtilities = inject(FormUtilitiesService);
  private _fb = inject(FormBuilder);
  formClient!: FormGroup;

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm() {
    this.formClient = this._fb.group({
      name: [''],
      surname: [''],
      email: [''],
      phone: [''],
      phone_optional: [''],
      address: [''],
      city: [''],
      province: [''],
      postal_code: [''],
      nif_cif: [''],
      observation: [''],
    });
  }

  onSubmit(): void {
    if (this.formClient.valid) {
      const formValue = this.formClient.value;
      this.save.emit(formValue);
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.formClient.reset();
  }
}
