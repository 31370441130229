import { Component, inject, OnInit, signal } from '@angular/core';
import {
  Column,
  DataTableComponent,
} from '../../components/data-table/data-table.component';
import { ButtonModule } from 'primeng/button';
import { MessageService, ConfirmationService } from 'primeng/api';
import { HeaderService } from '../../services/header.service';
import { Users, UserTable } from '../../interfaces/user';
import { CrudOperations } from '../../interfaces/crud_table';
import { UsersService } from '../../services/user.service';
import { forkJoin } from 'rxjs';

import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { CategoryAttributes } from '../../interfaces/category';

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [DataTableComponent, ButtonModule, AddEmployeeComponent],
  templateUrl: './employees.component.html',
})
export class EmployeesComponent implements OnInit, CrudOperations<any> {
  private readonly _headerService = inject(HeaderService);
  private readonly _userService = inject(UsersService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _router = inject(Router);

  protected employees = signal<Users>({} as Users);
  protected employeeTable = signal<UserTable[]>([]);
  protected totalRecords = signal<number>(0);
  protected pagination = signal<number>(1);
  protected loading = signal<boolean>(false);

  dialogVisible = false;

  protected columns: Column = [
    { field: 'id', header: 'ID', sortable: true },
    { field: 'name', header: 'Nombre', sortable: true },
    { field: 'username', header: 'Usuario', sortable: true },
    { field: 'email', header: 'Email', sortable: true },
    { field: 'categories', header: 'Categorías' },
  ];

  protected filters: string[] = ['name', 'email'];

  ngOnInit(): void {
    this._headerService.setHeader('Lista de Empleados', false);
    this.loadEmployees();
  }

  private loadEmployees(page: number = 1, pageSize: number = 10) {
    this.loading.set(true),
      this._userService
        .fetchUsers(page, pageSize)
        .pipe()
        .subscribe({
          next: (users) => {
            this.employees.set(users);
            this.totalRecords.set(users.meta?.pagination.total || 0);

            this.employeeTable.set(
              this.employees().data.map((user) => ({
                id: user.id,
                name: user.name,
                username: user.username,
                email: user.email,
                categories: user.categories?.map((cat) => cat.name) || [],
              }))
            );
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Empleados no encontrados',
              detail: 'Ha ocurrido un error al obtener los empleados',
            });
          },
          complete: () => {
            this.loading.set(false);
          },
        });
  }

  onAdd(): void {
    this.dialogVisible = true;
  }

  onEdit(item: any): void {
    this._router.navigate(['employees', item.id]);
  }

  onRemoveSelected(items: any[]): void {
    this.removeEmployees(items.map((item) => item.id));
  }

  onSave(employeeData: any): void {
    this.dialogVisible = false;

    this._userService.addUser(employeeData).subscribe({
      next: () => {
        this.ngOnInit();
        this._messageService.add({
          severity: 'success',
          summary: 'Empleado creado',
          detail: 'Empleado creado correctamente',
        });
      },
      error: (err) => {
        if (err.error.status === HttpStatusCode.BadRequest) {
          this._messageService.add({
            severity: 'error',
            summary: 'Empleado ya existe',
            detail: 'El empleado ya existe',
          });
          return;
        }
        this._messageService.add({
          severity: 'error',
          summary: 'Empleado no creado',
          detail: 'Ha ocurrido un error al crear el empleado',
        });
      },
    });
  }

  onPage(event: { page: number; rows: number }): void {
    const page = event.page + 1;
    const pageSize = event.rows;
    this.loadEmployees(page, pageSize);
  }

  private removeEmployees(ids: number[]) {
    this._confirmDialog.confirm({
      message: '¿Estás seguro de eliminar los empleados seleccionadas?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí',
      rejectLabel: 'No',

      accept: () => {
        const deleteRequests = ids.map((id) =>
          this._userService.deleteUser(id)
        );
        forkJoin(deleteRequests).subscribe({
          next: () => {
            this.ngOnInit();
            this._messageService.add({
              severity: 'success',
              summary: 'Empleados eliminados',
              detail: 'Empleados eliminados correctamente',
            });
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Empleados no eliminados',
              detail: 'Ha ocurrido un error al eliminar los empleados',
            });
          },
        });
      },
    });
  }
}
