import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FileUploadModule } from 'primeng/fileupload';

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [FileUploadModule, CommonModule],
  templateUrl: './upload.component.html',
})
export class UploadComponent {
  @Output() filesSelected = new EventEmitter<File[]>();
  files: File[] = [];

  onFileSelected(event: any): void {
    this.files.push(...event.files);
    this.filesSelected.emit(this.files);
  }

  onFileRemoved(event: any): void {
    const removedFile = event.file;
    this.files = this.files.filter((file) => file !== removedFile);
    this.filesSelected.emit(this.files);
  }
}
