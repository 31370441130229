import { HttpResponse, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { tap } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem('token');

  let authReq = req;
  if (token) {
    authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  console.log('Request:', authReq);

  return next(authReq).pipe(
    tap({
      next: (event) => {
        if (event instanceof HttpResponse) {
          console.log('Response:', event);
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error:', error);
      },
      complete: () => {
        console.log('Request completed');
      }
    })
  );
}

