import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Table, TableModule } from 'primeng/table';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { ToolbarModule } from 'primeng/toolbar';
import { PaginatorModule } from 'primeng/paginator';
import { transformServerity, transformStatus } from '../../utils/status';
import { TagComponent } from '../tag/tag.component';

export type Column = { field: string; header: string; sortable?: boolean }[];

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [
    TableModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    ButtonModule,
    MenuModule,
    ToolbarModule,
    PaginatorModule,
    TagComponent,
  ],
  templateUrl: './data-table.component.html',
})
export class DataTableComponent implements OnInit {
  protected selected: number[] = [];

  @Input() columns: Column = [];
  @Input() data: any[] = [];
  @Input() filters: string[] = [];
  @Input() totalRecords: number = 0;
  @Input() loading: boolean = false;
  @Output() edit = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() add = new EventEmitter<any>();
  @Output() removeSelected = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<any>();

  @ViewChild('table') table?: Table;
  menuItems: MenuItem[] = [];
  activeItem: any;

  ngOnInit(): void {
    this.menuItems = [
      {
        label: 'Editar',
        icon: 'pi pi-pencil',
        command: (event) => {
          this.onEdit(this.activeItem);
        },
      },
    ];
  }

  onGlobalFilter(event: Event) {
    const input = event.target as HTMLInputElement;
    if (this.table) {
      this.table.filterGlobal(input.value, 'contains');
    }
  }

  openNew() {
    this.add.emit();
  }

  private onEdit(row: any) {
    this.edit.emit(row);
  }

  // private onRemove(row: any) {
  //   this.remove.emit(row);
  // }

  deleteSelectedRows() {
    this.removeSelected.emit(this.selected);
    this.selected = [];
  }

  isCategoriesColumn(column: any): boolean {
    return column.field === 'categories';
  }

  isStatusColumn(column: any): boolean {
    return column.field === 'status';
  }
  onPage(event: any) {
    const page = event.page;
    const pageSize = event.rows || 10;
    this.pageChange.emit({ page, rows: pageSize });
  }

  transformServerity(status: any) {
    return transformServerity(status);
  }

  transformStatus(status: any): string | undefined {
    return transformStatus(status);
  }
}
