import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASEURL } from '../constant/url';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private readonly _http = inject(HttpClient);

  addNote(data: any): Observable<any> {
    return this._http.post<any>(BASEURL + 'notes', data);
  }

  deleteNote(id: number): Observable<any> {
    return this._http.delete<any>(BASEURL + 'notes/' + id);
  }

  updateNote(id: number, data: any): Observable<any> {
    return this._http.put<any>(BASEURL + 'notes/' + id, data);
  }
}
