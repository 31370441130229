import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Note, NoteAttributes } from '../../interfaces/note';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
} from 'primeng/api';
import { NoteService } from '../../services/note.service';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { CommonModule } from '@angular/common';
import { ScrollerModule } from 'primeng/scroller';
import { USERNAME } from '../../constant/storage';
import { SidebarModule } from 'primeng/sidebar';

@Component({
  selector: 'app-notes',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextareaModule,
    ReactiveFormsModule,
    MenuModule,
    DialogModule,
    MessagesModule,
    ScrollerModule,
    SidebarModule,
  ],
  templateUrl: './notes.component.html',
})
export class NotesComponent implements OnInit {
  @Input() notes!: Note[];
  @Input() idAgent?: number;
  @Input() idClient?: number;
  @Input() idBudget?: number;
  @Input() idService?: number;
  @Output() onAddNote = new EventEmitter<any>();
  @Output() onEditNote = new EventEmitter<any>();
  @Output() onDeleteNote = new EventEmitter<any>();

  private _fb = inject(FormBuilder);
  private readonly _messageService = inject(MessageService);
  private readonly _noteService = inject(NoteService);
  private readonly _confirmDialog = inject(ConfirmationService);

  protected selectedItem!: Note;
  protected formNote!: FormGroup;
  protected items: MenuItem[] = [];
  protected editDialogVisible = false;
  protected infoSidebarVisible = false;
  protected messages = signal<Message[]>([]);

  protected infoNote = signal<NoteAttributes>({} as NoteAttributes);

  ngOnInit(): void {
    this.formNote = this._fb.group({
      note: ['', [Validators.required, Validators.minLength(3)]],
      editNote: ['', [Validators.required, Validators.minLength(3)]],
    });

    this.items = [
      {
        label: 'Acciones',
        items: [
          {
            label: 'Información',
            icon: 'pi pi-info-circle',
            command: () => this.onInfo(),
          },
          {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: () => this.onEdit(),
          },
          {
            separator: true,
          },
          {
            label: 'Eliminar',
            icon: 'pi pi-trash',
            command: () => this.onDelete(),
            styleClass: 'menu-item-danger',
          },
        ],
      },
    ];

    this.initialMessage();
  }

  private initialMessage() {
    this.messages.set([
      {
        severity: 'info',
        summary: 'Sin Notas.',
      },
    ]);
  }

  onSubmit(): void {
    if (!this.formNote.get('note')?.valid) {
      return;
    }
    this.addNote();
  }

  onDelete(): void {
    this._confirmDialog.confirm({
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      message: '¿Estas seguro de eliminar esta nota?',
      accept: () => {
        this.deleteNote();
      },
    });
  }

  private addNote(): void {
    const note = {
      data: {
        agent: this.idAgent,
        client: this.idClient,
        budget: this.idBudget,
        service: this.idService,
        description: this.formNote.get('note')?.value,
        userCreated: localStorage.getItem(USERNAME),
      },
    };
    this._noteService.addNote(note).subscribe({
      next: (note) => {
        this._messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Nota agregada correctamente',
        });

        this.onAddNote.emit(note);
        this.formNote.reset();
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al agregar la nota',
        });
      },
    });
  }

  private onEdit(): void {
    this.editDialogVisible = true;
    this.formNote.patchValue({
      editNote: this.selectedItem.attributes.description,
    });
  }

  private onInfo(): void {
    this.infoSidebarVisible = true;
    this.infoNote.set(this.selectedItem.attributes);
  }

  updateNote(): void {
    this.editDialogVisible = false;
    if (!this.formNote.get('editNote')?.valid) {
      return;
    }

    const editNote = {
      data: {
        agent: this.idAgent,
        client: this.idClient,
        budget: this.idBudget,
        service: this.idService,
        description: this.formNote.get('editNote')?.value,
        userUpdated: localStorage.getItem(USERNAME),
      },
    };

    this._noteService.updateNote(this.selectedItem.id, editNote).subscribe({
      next: () => {
        this._messageService.add({
          severity: 'success',
          summary: 'Nota actualizada',
          detail: 'Nota actualizada correctamente',
        });
        this.onEditNote.emit(this.selectedItem);
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al actualizar la nota',
        });
      },
    });
  }
  private deleteNote(): void {
    this._noteService.deleteNote(this.selectedItem.id).subscribe({
      next: () => {
        this._messageService.add({
          severity: 'success',
          summary: 'Nota eliminada',
          detail: 'Nota eliminada correctamente',
        });
        this.onDeleteNote.emit(this.selectedItem.id);
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al eliminar la nota',
        });
      },
    });
  }
}
