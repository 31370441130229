import { Component } from '@angular/core';
import { MenuItemsComponent } from '../menu-items/menu-items.component';
import { MenuModule } from 'primeng/menu';
import { UserTagComponent } from '../user-tag/user-tag.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [MenuItemsComponent, MenuModule, UserTagComponent],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {}
