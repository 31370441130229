<form (ngSubmit)="onSubmit()" [formGroup]="formChangePassword">
  <div class="grid px-4 py-5 md:px-6">
    <div class="col-12">
      <div class="formgrid grid-nogutter">
        <!-- Primera fila de inputs -->
        <div class="col-12">
          <app-input
            name="password"
            formControlName="currentPassword"
            label="Contraseña"
            type="password"
          />
        </div>
        <div class="col-12">
          <app-input
            name="Nueva Contraseña"
            formControlName="password"
            label="Nueva Contraseña"
            type="password"
          />
        </div>
        <div class="col-12">
          <app-input
            name="Confirmar Contraseña"
            formControlName="passwordConfirmation"
            label="Confirmar Contraseña"
            type="password"
          />
        </div>
      </div>
    </div>
  </div>
</form>
