import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
} from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ContactPerson } from '../../interfaces/contact_people';
import { DividerModule } from 'primeng/divider';
import { ContactPeopleService } from '../../services/contact-people.service';
import { FormGroup } from '@angular/forms';
import { AddContactComponent } from './add-contact/add-contact.component';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-contact-people',
  standalone: true,
  imports: [
    CommonModule,
    MenuModule,
    ButtonModule,
    DividerModule,
    AddContactComponent,
    DialogModule,
    MessagesModule,
    AvatarModule,
    BadgeModule,
    CardModule,
  ],
  templateUrl: './contact-people.component.html',
})
export class ContactPeopleComponent implements OnInit {
  @Input() contacts!: ContactPerson[];
  @Input() idAgent: number | undefined;
  @Input() idClient: number | undefined;
  @Input() idBudget: number | undefined;
  @Input() idService: number | undefined;
  @Output() contactAdded = new EventEmitter<any>();
  @Output() contactUpdated = new EventEmitter<any>();
  @Output() contactDeleted = new EventEmitter<any>();

  private readonly _contactPeopleService = inject(ContactPeopleService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  protected contactForm!: FormGroup;
  protected selectedItem!: ContactPerson;
  protected items: MenuItem[] = [];
  protected addDialogVisible = false;

  protected editingContact: ContactPerson | null = null;
  protected observationDialogVisible = false;
  protected observation = signal<string>('');
  protected messages = signal<Message[]>([]);

  ngOnInit(): void {
    this.initialMessage();
  }

  addContact() {
    this.addDialogVisible = true;
    this.editingContact = null;
  }

  onSave(contact: any) {
    this.addDialogVisible = false;
    this._contactPeopleService.add(contact).subscribe({
      next: (newContact) => {
        this.contactAdded.emit(newContact);
        this._messageService.add({
          severity: 'success',
          summary: 'Contacto creado',
          detail: 'Contacto creado correctamente',
        });
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al crear el contacto',
        });
      },
    });
  }

  onUpdate(contact: any, id: number) {
    this.addDialogVisible = false;
    this._contactPeopleService.edit(contact, id).subscribe({
      next: (updatedContact) => {
        this.contactUpdated.emit(updatedContact);
        this._messageService.add({
          severity: 'success',
          summary: 'Contacto actualizado',
          detail: 'Contacto actualizado correctamente',
        });
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error al actualizar el contacto',
        });
      },
    });
  }

  private editContact() {
    this.addDialogVisible = true;
    this.editingContact = this.selectedItem;
  }

  private showObservations() {
    this.observation.set(this.selectedItem.attributes.observation);
    this.observationDialogVisible = true;
  }

  private callContact() {
    if (this.selectedItem.attributes.phone) {
      window.location.href = `tel:${this.selectedItem.attributes.phone}`;
    }
  }

  private sendEmail() {
    if (this.selectedItem.attributes.email) {
      window.location.href = `mailto:${this.selectedItem.attributes.email}`;
    }
  }

  private removeContact() {
    this._confirmDialog.confirm({
      message: '¿Estás seguro de eliminar este contacto?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí',
      rejectLabel: 'No',

      accept: () => {
        if (!this.selectedItem) return;
        this._contactPeopleService.delete(this.selectedItem?.id).subscribe({
          next: () => {
            this._messageService.add({
              severity: 'success',
              summary: 'Contacto eliminado',
              detail: 'Contacto eliminado correctamente',
            });
            this.contactDeleted.emit(this.selectedItem?.id);
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar el contacto',
            });
          },
        });
      },
    });
  }

  updateMenu() {
    this.items = [
      {
        label: 'Acciones',
        items: [
          {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: () => this.editContact(),
          },
          {
            label: 'Observaciones',
            icon: 'pi pi-comment',
            command: () => this.showObservations(),
            disabled: this.isObservationDisabled,
          },
          {
            label: 'Llamar',
            icon: 'pi pi-phone',
            command: () => this.callContact(),
            disabled: this.isPhoneDisabled,
          },
          {
            label: 'Email',
            icon: 'pi pi-at',
            command: () => this.sendEmail(),
            disabled: this.isEmailDisabled,
          },
          {
            separator: true,
          },
          {
            label: 'Eliminar',
            icon: 'pi pi-trash',
            command: () => this.removeContact(),
            styleClass: 'menu-item-danger',
          },
        ],
      },
    ];
  }

  private get isObservationDisabled(): boolean {
    const { observation } = this.selectedItem?.attributes;
    return observation === '' || observation === null;
  }

  private get isPhoneDisabled(): boolean {
    const { phone } = this.selectedItem?.attributes;
    return phone === '' || phone === null;
  }

  private get isEmailDisabled(): boolean {
    const { email } = this.selectedItem?.attributes;
    return email === '' || email === null;
  }

  private initialMessage() {
    this.messages.set([
      {
        severity: 'info',
        summary: 'Sin Contactos.',
      },
    ]);
  }
}
