import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASEURL } from '../constant/url';
import { MediaFile, MediaFileResponse } from '../interfaces/media_file';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private readonly _http = inject(HttpClient);

  uploadFiles(files: File[]): Observable<MediaFile[]> {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));
    return this._http.post<MediaFile[]>(`${BASEURL}upload`, formData);
  }

  deleteFile(file: MediaFile): Observable<any> {
    return this._http.delete(`${BASEURL}upload/files/${file.id}`);
  }
}
