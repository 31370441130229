import { Component } from '@angular/core';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-skelton',
  standalone: true,
  imports: [SkeletonModule],
  templateUrl: './skelton.component.html',
})
export class SkeltonComponent {}
