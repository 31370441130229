import { Component, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItemsComponent } from '../menu-items/menu-items.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { HeaderPagesComponent } from '../header-pages/header-pages.component';
import { SidebarModule } from 'primeng/sidebar';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { UserTagComponent } from '../user-tag/user-tag.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    SidebarModule,
    MenuItemsComponent,
    NavbarComponent,
    RouterModule,
    ConfirmDialogModule,
    ToastModule,
    HeaderPagesComponent,
    SidebarComponent,
    UserTagComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css',
  providers: [MessageService, ConfirmationService, DialogService],
})
export class LayoutComponent {
  sidebarVisible = signal<boolean>(false);

  handleToggleSidebar() {
    this.sidebarVisible.set(!this.sidebarVisible());
  }
}
