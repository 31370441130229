<div class="flex field w-full">
  <span class="w-full">
    <label class="font-semibold">Adjuntos</label>

    <p-fileUpload
      name="files[]"
      customUpload="true"
      [chooseLabel]="'Seleccionar'"
      chooseIcon="pi pi-fw pi-upload"
      (onSelect)="onFileSelected($event)"
      (onRemove)="onFileRemoved($event)"
      [multiple]="true"
      [showUploadButton]="false"
      [showCancelButton]="false"
      accept=".png,.jpg,.jpeg,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.txt,.csv"
      maxFileSize="1000000"
    >
      <ng-template pTemplate="empty">
        <div class="flex justify-content-center">
          <span class="text-center">
            <i
              class="pi pi-cloud-upload border-2 border-round-3xl rounded-full text-primary p-5 text-8xl"
            ></i>
            <p class="mt-4">Arrastra tus archivos o haz click en seleccionar</p>
          </span>
        </div>
      </ng-template>
    </p-fileUpload>
  </span>
</div>
