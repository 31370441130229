import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-chage-password',
  standalone: true,
  imports: [ReactiveFormsModule, InputComponent],
  templateUrl: './chage-password.component.html',
})
export class ChagePasswordComponent implements OnInit {
  @Output() changePasswordEvent = new EventEmitter<any>();
  private _fb = inject(FormBuilder);
  protected formChangePassword!: FormGroup;

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm() {
    this.formChangePassword = this._fb.group({
      password: [''],
      currentPassword: [''],
      passwordConfirmation: [''],
    });
  }

  onSubmit() {
    this.changePasswordEvent.emit(this.formChangePassword.value);
  }
}
