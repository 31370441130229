import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToolbarComponent } from '../../../components/toolbar/toolbar.component';
import { ContactPeopleComponent } from '../../../components/contact-people/contact-people.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Client } from '../../../interfaces/client';
import { ContactPerson } from '../../../interfaces/contact_people';
import { ClientService } from '../../../services/client.service';
import { HeaderService } from '../../../services/header.service';
import { Agent } from '../../../interfaces/agent';
import { AgentComponent } from '../../../components/agent/agent.component';
import { FormUtilitiesService } from '../../../services/from.utilitilies.service';
import { InputComponent } from '../../../components/input/input.component';
import { Note } from '../../../interfaces/note';
import { DataViewModule } from 'primeng/dataview';
import { NotesComponent } from '../../../components/notes/notes.component';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-client-details',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextareaModule,
    ButtonModule,
    ReactiveFormsModule,
    ToolbarComponent,
    ContactPeopleComponent,
    AgentComponent,
    InputComponent,
    DataViewModule,
    TabViewModule,
    NotesComponent,
  ],
  templateUrl: './client-details.component.html',
})
export class ClientDetailsComponent implements OnInit {
  private readonly _headerService = inject(HeaderService);
  private readonly _activeRoute = inject(ActivatedRoute);
  private readonly _clientService = inject(ClientService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _router = inject(Router);
  private _fb = inject(FormBuilder);

  protected readonly idClient = signal<number>(0);
  protected formUtilities = inject(FormUtilitiesService);
  protected client = signal<Client>({} as Client);
  protected contacts = signal<ContactPerson[]>([]);
  protected agent = signal<Agent>({} as Agent);
  protected notes = signal<Note[]>([]);
  protected formClient!: FormGroup;

  ngOnInit(): void {
    this._activeRoute.params.subscribe((params) => {
      this.idClient.set(params['id']);
    });
    this._headerService.setHeader(
      'Detalles del Cliente #' + this.idClient(),
      true
    );
    this.getClient();
    this.loadForm();
  }

  private getClient() {
    this._clientService.fetchClient(this.idClient()).subscribe({
      next: (client) => {
        this.client.set(client);
        if (client.attributes.contact_people) {
          this.contacts.set(client.attributes.contact_people.data);
        }
        if (client.attributes.agent) {
          this.agent.set(client.attributes.agent);
        }
        if (client.attributes.notes) {
          this.notes.set(client.attributes.notes.data);
        }
        this.updateForm();
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Cliente no encontrado',
          detail: 'Ha ocurrido un error al obtener el cliente',
        });
      },
    });
  }

  private loadForm() {
    this.formClient = this._fb.group({
      name: [''],
      email: [''],
      phone: [''],
      phone_optional: [''],
      address: [''],
      city: [''],
      province: [''],
      postal_code: [''],
      nif_cif: [''],

      contact_people: [[]],
      agent: [[]],
    });
  }

  private updateForm() {
    const {
      name,
      email,
      phone,
      phone_optional,
      address,
      city,
      province,
      postal_code,
      nif_cif,
      contact_people,
      agent,
    } = this.client().attributes;
    this.formClient.patchValue({
      name,
      email,
      phone,
      phone_optional,
      address,
      city,
      province,
      postal_code,
      nif_cif,
      contact_people,
      agent,
    });
  }

  editClient() {
    if (this.formClient.invalid) {
      this.formClient.markAllAsTouched();
      return;
    }

    const clientData = this.formClient.value;
    this._clientService.updateClient(this.idClient(), clientData).subscribe({
      next: (client) => {
        this.client.set(client);
        if (client.attributes.agent) {
          this.agent.set(client.attributes.agent);
        } else {
          this.agent.set({} as Agent);
        }
        this.updateForm();
        this._messageService.add({
          severity: 'success',
          summary: 'Cliente actualizado',
          detail: 'Cliente actualizado correctamente',
        });
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se ha podido actualizar el cliente',
        });
      },
    });
  }

  deleteClient() {
    this._confirmDialog.confirm({
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      message: '¿Seguro que quieres borrar este cliente?',
      accept: () => {
        this._clientService.deleteClient(this.idClient()).subscribe({
          next: () => {
            this._messageService.add({
              severity: 'success',
              summary: 'Cliente borrado',
              detail: 'Cliente borrado correctamente',
            });
            this._router.navigate(['/clients']);
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Ha ocurrido un error',
              detail: 'No se ha podido borrar el cliente',
            });
          },
        });
      },
    });
  }

  onContactAdded(contact: any) {
    const newContact: ContactPerson = {
      id: contact.data.id,
      attributes: contact.data.attributes,
    };
    this.contacts().push(newContact);
  }

  onContactUpdated(contact: any) {
    this.contacts.update((contacts) => {
      const index = contacts.findIndex((c) => c.id === contact.data.id);
      if (index !== -1) {
        contacts[index] = {
          ...contacts[index],
          ...contact.data,
        };
      }
      return [...contacts];
    });
  }

  onContactDeleted(id: any) {
    this.contacts.update((contacts) =>
      contacts.filter((contact) => contact.id !== id)
    );
  }

  onAgentSelected(agentId: number): void {
    this.formClient.patchValue({ agent: agentId });

    this.editClient();
  }

  onAgentRemoved(): void {
    this._confirmDialog.confirm({
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      message: '¿Seguro que quieres quitar el agente?',
      acceptLabel: 'Sí',

      accept: () => {
        this.formClient.patchValue({ agent: null });
        this.editClient();
      },
    });
  }

  onAddNote(note: any) {
    this.notes.update((notes) =>
      [...notes, note.data].sort((a, b) => b.id - a.id)
    );
  }
  onEditNote($event: any) {
    this.notes.update((notes) => {
      const index = notes.findIndex((n) => n.id === $event.data.id);
      if (index !== -1) {
        notes[index] = {
          ...notes[index],
          ...$event.data,
        };
      }
      return [...notes];
    });
  }
  onDeleteNote($event: any) {
    this.notes.update((notes) => notes.filter((note) => note.id !== $event));
  }
}
