<div class="layout-wrapper">
  <div class="topbar block lg:hidden">
    <app-navbar (toggleSidebar)="handleToggleSidebar()"></app-navbar>
  </div>

  <div class="layout-main">
    <app-sidebar />
    <p-sidebar [(visible)]="sidebarVisible" [modal]="true">
      <app-user-tag />

      <app-menu-items
        (itemClicked)="sidebarVisible.set(false)"
      ></app-menu-items>
    </p-sidebar>

    <div class="content">
      <app-header-pages />
      <p-confirmDialog />
      <p-toast
        [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
      />

      <router-outlet />
    </div>
  </div>
</div>
