<div class="field gap-3">
  @if (control) {

  <label for="{{ name }}" class="font-semibold">{{ label }}</label>
  @if (icon) {
  <p-iconField iconPosition="left">
    <p-inputIcon [styleClass]="'pi ' + icon"></p-inputIcon>
    <input
      id="{{ name }}"
      pInputText
      autocomplete="on"
      [placeholder]="placeholder ? placeholder : ''"
      [type]="type"
      [attr.value]="value || null"
      [formControl]="control"
      [attr.disabled]="control.disabled ? true : null"
      class="text-color-secondary"
    />
  </p-iconField>
  } @else {
  <input
    id="{{ name }}"
    pInputText
    autocomplete="on"
    [placeholder]="placeholder ? placeholder : ''"
    [type]="type"
    [attr.value]="value || null"
    [formControl]="control"
    [attr.disabled]="control.disabled ? true : null"
    class="text-color-secondary"
  />

  } @if (!isValid()) {
  <app-error-input
    [formContained]="control"
    [controlName]="name"
    [customErrorMessages]="customErrorMessages"
  ></app-error-input>
  } }
</div>
