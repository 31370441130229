import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccesorDirective } from './control-value/control-value.component';
import { InputType } from './input.interface';
import { ErrorInputComponent } from './error-input/error-input.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
@Component({
  selector: 'app-input',
  standalone: true,
  templateUrl: './input.component.html',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorInputComponent,
    InputIconModule,
    IconFieldModule,
    InputTextModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent<T>
  extends ControlValueAccesorDirective<T>
  implements InputType
{
  @Input() id = '';
  @Input() label = '';
  @Input() placeholder?: string | undefined;
  @Input() value?: string | number | undefined;
  @Input() customErrorMessages: Record<string, string> = {};
  @Input() name!: string;
  @Input() icon: string | undefined;
  @Input()
  set disabled(value: boolean) {
    this.setDisabledState(value);
  }
}
