<app-data-table
  [columns]="columns"
  [data]="clientsTable()"
  [filters]="filters"
  [totalRecords]="totalRecords()"
  (add)="onAdd()"
  (edit)="onEdit($event)"
  (pageChange)="onPage($event)"
  (removeSelected)="onRemoveSelected($event)"
/>
<app-add-client [(visible)]="dialogVisible" (save)="onSave($event)" />
