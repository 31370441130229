<div class="field">
  <label for="client" class="font-semibold">Cliente</label>
  <p-dropdown
    id="client"
    [options]="clientOptions()"
    (onChange)="onClientChange($event)"
    optionLabel="label"
    optionValue="value"
    placeholder="Seleccione Cliente"
    [filter]="true"
    [showClear]="true"
    [checkmark]="true"
    appendTo="body"
    autoWidth="false"
    [style]="{ minWidth: '15rem', width: '100%' }"
  />
</div>
