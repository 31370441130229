import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormUtilitiesService {
  getValidators(
    formGroup: FormGroup,
    fieldName: string,
    minLength?: number
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (minLength !== undefined) {
      validators.push(Validators.minLength(minLength));
    }

    const fieldControl = formGroup.get(fieldName);
    if (fieldControl && fieldControl.validator) {
      return [fieldControl.validator];
    }
    return [];
  }

  getControl(formGroup: FormGroup, fieldName: string): FormControl {
    return formGroup.get(fieldName) as FormControl;
  }
}
