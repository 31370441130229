<form (ngSubmit)="editUser()" [formGroup]="formUser">
  <app-toolbar
    [createdAt]="(user().createdAt ? user().createdAt : '') || ''"
    [updatedAt]="(user().updatedAt ? user().updatedAt : '') || ''"
    (onEdit)="editUser()"
    (onDelete)="deleteUser()"
  />
  <div class="surface-card shadow-2 border-round pt-4 px-5">
    <span class="flex align-items-center gap-2 pb-3 pt-2">
      <i class="pi pi-info-circle"></i>
      <span class="font-semibold">Información</span>
    </span>
    <p-divider></p-divider>
    <div class="flex justify-content-end">
      <p-button
        icon="pi pi-key"
        label="Cambiar contraseña"
        (onClick)="visible = true"
        [text]="true"
        severity="secondary"
      />
    </div>

    <div class="grid px-4 py-5 md:px-6 lg:px-8">
      <div class="col-12">
        <div class="formgrid grid">
          <!-- Primera fila de inputs -->
          <div class="col-12 md:col-6">
            <app-input
              name="name"
              formControlName="name"
              label="Nombre"
              type="text"
            />
          </div>
          <div class="col-12 md:col-6">
            <app-input
              name="Nombre usuario"
              formControlName="username"
              label="Nombre usuario"
              type="text"
            />
          </div>
        </div>
        <div class="formgrid grid">
          <!-- Segunda fila de inputs -->
          <div class="col-12 md:col-6">
            <app-input
              name="email"
              formControlName="email"
              label="Email"
              type="email"
            />
          </div>
          <div class="col-12 md:col-6">
            <app-input
              name="phone"
              formControlName="phone"
              label="Teléfono"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<p-dialog
  header="Cambiar Contraseña"
  [modal]="true"
  [maximizable]="true"
  [(visible)]="visible"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ width: '30vw' }"
  (onHide)="visible = false"
>
  <span class="block mb-5 p-text-secondary"
    >Introduce los datos para cambiar la contraseña</span
  >
  <app-chage-password #changePasswordForm />

  <ng-template pTemplate="footer">
    <p-button
      label="Cancelar"
      severity="secondary"
      icon="pi pi-times"
      (onClick)="visible = false"
    />
    <p-button
      label="Aceptar"
      icon="pi pi-check"
      (onClick)="onSubmitChangePassword(changePasswordForm)"
    />
  </ng-template>
</p-dialog>
