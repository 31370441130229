import { Component, inject, OnInit, signal } from '@angular/core';
import { HeaderService } from '../../../services/header.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Agent } from '../../../interfaces/agent';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentService } from '../../../services/agent.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { ToolbarComponent } from '../../../components/toolbar/toolbar.component';
import { ContactPerson } from '../../../interfaces/contact_people';
import { ContactPeopleComponent } from '../../../components/contact-people/contact-people.component';
import { FormUtilitiesService } from '../../../services/from.utilitilies.service';
import { InputComponent } from '../../../components/input/input.component';
import { TabViewModule } from 'primeng/tabview';
import { Note } from '../../../interfaces/note';
import { DataViewModule } from 'primeng/dataview';
import { NotesComponent } from '../../../components/notes/notes.component';

@Component({
  selector: 'app-agent-details',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    InputTextareaModule,
    ButtonModule,
    ReactiveFormsModule,
    ToolbarComponent,
    InputComponent,
    ContactPeopleComponent,
    TabViewModule,
    DataViewModule,
    NotesComponent,
  ],

  templateUrl: './agent-details.component.html',
})
export class AgentDetailsComponent implements OnInit {
  private readonly _headerService = inject(HeaderService);
  private readonly _activeRoute = inject(ActivatedRoute);
  private readonly _agentService = inject(AgentService);
  private readonly _messageService = inject(MessageService);
  private readonly _confirmDialog = inject(ConfirmationService);
  private readonly _router = inject(Router);
  private _fb = inject(FormBuilder);

  protected readonly idAgent = signal<number>(0);
  protected formUtilities = inject(FormUtilitiesService);
  protected agent = signal<Agent>({} as Agent);
  protected contacts = signal<ContactPerson[]>([]);
  protected notes = signal<Note[]>([]);
  protected formAgent!: FormGroup;

  ngOnInit(): void {
    this._activeRoute.params.subscribe((params) => {
      this.idAgent.set(params['id']);
      this._headerService.setHeader(
        'Detalles del Agente #' + this.idAgent() + '',
        true
      );
    });
    this.getAgent();
    this.loadForm();
  }

  private loadForm() {
    this.formAgent = this._fb.group({
      name: [''],
      surname: [''],
      email: [''],
      phone: [''],
      phone_optional: [''],
      address: [''],
      city: [''],
      province: [''],
      postal_code: [''],
      nif_cif: [''],
      observation: [''],
      contact_people: [[]],
      note: [''],
    });
  }

  private getAgent() {
    this._agentService.fetchAgent(this.idAgent()).subscribe({
      next: (agent) => {
        this.agent.set(agent);
        if (agent.attributes.contact_people) {
          this.contacts.set(agent.attributes.contact_people.data);
        }
        if (agent.attributes.notes) {
          this.notes.set(
            agent.attributes.notes.data.sort((a, b) => b.id - a.id)
          );
        }
        this.updateForm();
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Agente no encontrado',
          detail: 'Ha ocurrido un error al obtener el agente',
        });
      },
    });
  }

  private updateForm() {
    console.log('Agent:', this.agent());
    const {
      name,
      surname,
      email,
      phone,
      phone_optional,
      address,
      city,
      province,
      postal_code,
      nif_cif,
      observation,
      contact_people,
    } = this.agent()?.attributes;
    this.formAgent.patchValue({
      name,
      surname,
      email,
      phone,
      phone_optional,
      address,
      city,
      province,
      postal_code,
      nif_cif,
      observation,
      contact_people,
    });
  }

  editAgent() {
    if (this.formAgent.invalid) {
      this.formAgent.markAllAsTouched();
      return;
    }
    const agentData = this.formAgent.value;
    this._agentService.updateAgent(this.idAgent(), agentData).subscribe({
      next: (agent) => {
        this.agent.set(agent);

        this._messageService.add({
          severity: 'success',
          summary: 'Agente actualizado',
          detail: 'Agente actualizado correctamente',
        });
      },
      error: () => {
        this._messageService.add({
          severity: 'error',
          summary: 'Ha ocurrido un error',
          detail: 'No se ha podido actualizar el agente',
        });
      },
    });
  }

  deleteAgent() {
    this._confirmDialog.confirm({
      header: 'Confirmación',
      message: '¿Esta seguro de eliminar este agente?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this._confirmDialog.close();
        this._agentService.deleteAgent(this.idAgent()).subscribe({
          next: () => {
            this._messageService.add({
              severity: 'success',
              summary: 'Contacto eliminado',
              detail: 'Contacto eliminado correctamente',
            });
            this._router.navigate(['/agents']);
          },
          error: () => {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al eliminar el contacto',
            });
          },
        });
      },
    });
  }

  onContactAdded(contact: any) {
    const newContact: ContactPerson = {
      id: contact.data.id,
      attributes: contact.data.attributes,
    };
    this.contacts().push(newContact);
  }

  onContactUpdated(contact: any) {
    this.contacts.update((contacts) => {
      const index = contacts.findIndex((c) => c.id === contact.data.id);
      if (index !== -1) {
        contacts[index] = {
          ...contacts[index],
          ...contact.data,
        };
      }
      return [...contacts];
    });
  }

  onContactDeleted(id: any) {
    this.contacts.update((contacts) =>
      contacts.filter((contact) => contact.id !== id)
    );
  }

  onAddNote(note: any) {
    this.notes.update((notes) =>
      [...notes, note.data].sort((a, b) => b.id - a.id)
    );
  }

  onEditNote($event: any) {
    this.notes.update((notes) => {
      const index = notes.findIndex((n) => n.id === $event.id);
      if (index !== -1) {
        notes[index] = {
          ...notes[index],
          ...$event,
        };
      }
      return [...notes];
    });
  }
  onDeleteNote($event: any) {
    this.notes.update((notes) => notes.filter((note) => note.id !== $event));
  }
}
