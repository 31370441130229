<div class="flex flex-row align-items-center p-3">
  <app-avatar />
  <div class="overflow-auto ml-2">
    <h4 class="mb-1">{{ username }}</h4>
    <p
      class="text-xs text-color-secondary mt-0 overflow-auto text-overflow-ellipsis"
    >
      {{ role() }}
    </p>
  </div>

  <i
    class="pi pi-fw pi-angle-down text-sm text-color-secondary cursor-pointer ml-auto"
    (click)="menu.toggle($event)"
  ></i>
</div>

<p-menu #menu [model]="items" [popup]="true" [appendTo]="'body'" />
