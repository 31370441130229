import { Component, OnInit } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { BadgeModule } from 'primeng/badge';
import { USERNAME } from '../../constant/storage';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [AvatarModule, MenuModule, BadgeModule],
  templateUrl: './avatar.component.html',
})
export class AvatarComponent implements OnInit {
  username = '';
  items: MenuItem[] = [];

  ngOnInit(): void {
    this.username = localStorage.getItem(USERNAME) || '';
  }
}
