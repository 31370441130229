import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FormUtilitiesService } from '../../../services/from.utilitilies.service';
import { InputComponent } from '../../../components/input/input.component';

@Component({
  selector: 'app-add-category',
  standalone: true,
  imports: [
    DialogModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    CommonModule,
    InputComponent,
  ],
  templateUrl: './add-edit-category.component.html',
})
export class AddEditCategoryComponent implements OnInit {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Output() save = new EventEmitter<any>();
  private _fb = inject(FormBuilder);
  protected formUtilities = inject(FormUtilitiesService);
  formCategory!: FormGroup;

  ngOnInit(): void {
    this.loadForm();
  }

  private loadForm(): void {
    this.formCategory = this._fb.group({
      name: [''],
    });
  }

  closeDialog(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
    this.formCategory.reset();
  }

  onSubmit(): void {
    if (this.formCategory.valid) {
      this.save.emit(this.formCategory.value);
      this.closeDialog();
    }
  }
}
